import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Home.css";
import { Container, Row, Col } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import Presentation from "./Presentation";
import Navbar2 from "./Navbar2";
import Hero from "./Hero";
import QuraniFeedback from "./Student/Settings/QuraniFeedBack";
import Timeline from "./Timeline";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const specialword = {
    color: "#f05d0e",
    fontWeight: "700",
  };
  // const [isCollapsed, setIsCollapsed] = useState(true);

  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div>
      <section id="navbar">
        <Navbar2 />
      </section>

      <section id="hero">
        <Hero />
      </section>

      <section data-aos="fade-up" id="timeline">
        <Timeline />
      </section>

      <section id="signin">
        <h1 data-aos="fade-up" className="advicetitle">
          انضم إلى منصة <span style={specialword}>تعاهدوا </span>
        </h1>

        <Container className="sign">
          <Row>
            <Col sm={4}>
              <div data-aos="fade-up" className="signcard">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/jamia.png"}
                    className="signpic"
                    alt=""
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "#117eb1",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "50%",
                      fontWeight: "bold",
                      fontSize: "20px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    1
                  </div>
                </div>
                <h3 className="signtext">نرحب بانضمام جمعيتكم في منصتنا</h3>
                <Link className="signbtn" to="/addjamiia">
                  سجل الآن
                </Link>
              </div>
            </Col>

            <Col sm={4}>
              <div data-aos="fade-up" className="signcard">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/teacher.png"}
                    className="signpic"
                    alt=""
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "#117eb1",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "50%",
                      fontWeight: "bold",
                      fontSize: "20px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    2
                  </div>
                </div>
                <h3 className="signtext">انضم إلى منصة تعاهدوا كمعلم</h3>
                <Link className="signbtn" to="/teacherauth">
                  سجل الآن
                </Link>
              </div>
            </Col>

            <Col sm={4}>
              <div data-aos="fade-up" className="signcard">
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/student.png"}
                    className="signpic"
                    alt=""
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      backgroundColor: "#117eb1",
                      color: "white",
                      padding: "5px 15px",
                      borderRadius: "50%",
                      fontWeight: "bold",
                      fontSize: "20px",
                      boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    3
                  </div>
                </div>
                <h3 className="signtext"> انضم إلى منصة تعاهدوا كطالب </h3>
                <Link className="signbtn" to="/studentauth">
                  سجل الآن
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="barnamaj">
        <h1 data-aos="fade-up" className="advicetitle">
          برنامج الحفظ المتبع في منصة <span style={specialword}>تعاهدوا </span>؟
        </h1>
        <h5 data-aos="fade-up" className="explicationTimeline">
          هذا البرنامج أسهم في وضعه ثلة من كبار المشايخ و القراء التونسيين
        </h5>

        <Container>
          <Row>
            <Col sm={4}>
              <div data-aos="fade-up" className="advicecard">
                <h3 className="advicetitle"> التسميع و الإختبارات </h3>
                <img
                  src={process.env.PUBLIC_URL + "/images/jamia2.png"}
                  className="advicepic"
                  alt=""
                />
                <p className="advicetext">
                  على كل طالب أن يقرأ على مدرسه نصيباً من حفظه القديم في كل حصة
                  و أن يقرأ عليه أيضاً الحفظ الجديد الخاص بتلك الحصة. كما يقوم
                  المدرس بإختبارات دورية لكل طالب لحثهم على مزيد المراجعة و
                  التعود على المسابقات
                </p>
              </div>
            </Col>

            <Col sm={4}>
              <div data-aos="fade-up" className="advicecard">
                <h3 className="advicetitle"> المراجعة</h3>
                <img
                  src={process.env.PUBLIC_URL + "/images/student2.png"}
                  className="advicepic"
                  alt=""
                />
                <p className="advicetext">
                  المراجعة تكون بقراءة نصيب من الورد اليومي غيبا (بدون مصحف) و
                  ينصح للطالب بإتمام النصيب المحفوظ في مدة لا تتجاوز الأسبوع.
                  مثال إن كان الطالب يحفظ 10 احزاب فإن ورده اليومي يجب أن يكون
                  حزب و نصف
                </p>
              </div>
            </Col>

            <Col sm={4}>
              <div data-aos="fade-up" className="advicecard">
                <h3 className="advicetitle"> الحفظ</h3>
                <img
                  src={process.env.PUBLIC_URL + "/images/teacher2.png"}
                  className="advicepic"
                  alt=""
                />
                <p className="advicetext">
                  يحدد الطالب مستأنسا بشيخه المقدار الذي سيحفظه كل أسبوع. على
                  الطالب أن يكرر الحفظ الجديد الخاص بكل يوم 50 مرة و هذا التكرار
                  يكون بعد الإنتهاء من الحفظ و ليس من أجل الحفظ
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section data-aos="fade-up" id="momayizat">
        <Presentation />
      </section>

      <section data-aos="fade-up" id="hadith">
        <div className="container-fluid" id="about-container">
          <div className="row" id="banner-row">
            <div className="col-md-6" id="banner-col2">
              <img
                className="img-responsive rounded mx-auto d-block"
                src={process.env.PUBLIC_URL + "/images/hadith.png"}
                alt=""
              />
            </div>
            <div className="col-md-6" id="about-col">
              <h5 className="arabicText">قال رسول الله صلى الله عليه وسلم</h5>
              <h2 className="arabicText" id="hadith">
                <span className="taahaduWord">تعاهدوا</span> هَذَا القُرْآنَ،
                فَوَالَّذِي نَفْسُ مُحَمَّدٍ بِيَدِهِ لَهُوَ أشَدُّ تَفَلُّتاً
                مِنَ الإبلِ فِي عُقُلِهَا
              </h2>
              <h5 className="arabicText">رواه مسلم</h5>
              <p className="arabicText" id="hadithExp">
                تعاهدوا : حافظوا على قراءته.
              </p>
              <p className="arabicText" id="hadithExp">
                تفلتاً : التفلت: التخلص من الشيء فجأة من غير تمكّث.
              </p>
              <p className="arabicText" id="hadithExp">
                عُقُلها : جمع عِقَالٍ، وهو حبل يشد به البعير في وسط الذراع.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section data-aos="fade-up" id="serviceThree">
        <h1 className="text-center">حفظ القرآن ينقسم إلى ثلاثة مراحل</h1>
        <div className="container-fluid" id="service-container">
          <div className="row" id="banner-row">
            <div className="col-md-4 homeServices" id="service-col1">
              <img
                src={process.env.PUBLIC_URL + "/images/mourajaa.png"}
                className="img-fluid rounded mx-auto d-block"
                alt="..."
              />
              <h3 className="threeParts">الورد اليومي</h3>

              <p className="homeParagraphs">
                والورد في القرآن هو ما اعتاد الشخص على قراءته بشكل يومي ومنتظم،
                فقال رسول الله صلى الله عليه وسلم " اقْرَؤُوا القُرْآنَ فإنَّه
                يَأْتي يَومَ القِيامَةِ شَفِيعًا لأَصْحابِهِ" صدق رسول الله صلى
                الله عليه وسلم.
              </p>
            </div>
            <div className="col-md-4 homeServices" id="service-col2">
              <img
                src={process.env.PUBLIC_URL + "/images/mentwo.png"}
                className="img-fluid rounded mx-auto d-block"
                alt="..."
              />
              <h3 className="threeParts">التكرار</h3>

              <p className="homeParagraphs">
                حفظ القرآن العظيم من أفضل الأعمال التي تُقرِّب العبد من ربّه،
                وتجدر الإشارة إلى أنّ كلّ علمٍ لا بدّ من حِفْظه، والحِفْظ لا
                يتحقّق إلّا بالتكرار، وبذلك يتحقّق الإتقان، والتثبيت، وعدم
                النسيان، كما تزداد الحسنات والأجور، وتتضاعف؛ فالتكرار يحقّق
                الحِفْظ والأُجور العظيمة
              </p>
            </div>
            <div className="col-md-4 homeServices" id="service-col3">
              <img
                src={process.env.PUBLIC_URL + "/images/hefdh.png"}
                className="img-fluid rounded mx-auto d-block"
                alt="..."
              />
              <h3 className="threeParts">الحفظ</h3>

              <p className="homeParagraphs">
                لا يمكن حِفظ القرآن الكريم دون عَونٍ من الله، ولذلك يُعَدّ
                الإخلاص لله -تعالى في حِفظ القرآن، والاستعانة به من أنفع الطرق،
                وأفضلهاوتجدر الإشارة إلى أنّ هذه الطريقة تتمثّل بضرورة وجود شيخ
                مُتقِن للتجويد؛ تلاوةً، وترتيلاً؛ .ليأخذَ الحافظ بإرشاداته،
                ويتلو عليه ما حَفِظه من آيات، فيُصحِّح له الشيخ تلاوته
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section data-aos="fade-up" id="contactUs">
        <QuraniFeedback />
      </section>

      <section className="container-fluid sec-footer" id="footer">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="card desc-box">
                <div className="card-body">
                  <div className="card-title"> تعاهدوا</div>
                  <div className="card-text">
                    منصة تفاعلية متكاملة هدفها مرافقة الطالب في رحلة حفظ القرآن
                    الكريم
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card desc-box">
                <div className="card-body">
                  <div className="card-title"> تواصل معنا </div>
                  <div className="card-text">
                    <div className="row">
                      <div className="col">
                        <i className="fas fa-phone ml-3"></i>
                        <span> 50.790.136 216+ </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <i className="fas fa-map-marker ml-3"></i>
                        <span> تونس - نابل - المعمورة </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <i className="fas fa-envelope-open ml-3"></i>
                        <span>taahadu@gmail.com</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col text-center">
              <img
                src={process.env.PUBLIC_URL + "/images/Orange.png"}
                className="footerLogo"
                alt="logo"
              />
              <br className="clearfix" />
              <p className="ayaFooter">
                َتبَارَكَ الَّذِي نَزَّلَ الْفُرْقَانَ عَلَى عَبْدِهِ لِيَكُونَ
                لِلْعَالَمِينَ نَذِيراً
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid sec-sub-footer">
        <div className="container">
          <div className="row">
            <div className="col jami37ou9ou9">
              <button className="goToTopButton" onClick={topFunction}>
                <i className="fas fa-arrow-up"></i> العودة للأعلى
              </button>
              <span>الجمعية القرآنية بالمعمورة © 2023 </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
