import React from "react";

const NextPrevNotMahfoudha = (props) => {
  const wordsPerPage = 7;

  if (props.souarNotMahfoudha?.length > 11) {
    return (
      <div>
        <button
          className="nextPrevHefdhBtnTwo"
          disabled={props.currentPage === 1}
          onClick={() => props.setCurrentPage(props.currentPage - 1)}
        >
          السابق
        </button>
        <span>
          {props.currentPage} of{" "}
          {Math.ceil(props.souarNotMahfoudha?.length / wordsPerPage)}
        </span>
        <button
          className="nextPrevHefdhBtnTwo"
          disabled={
            props.currentPage ===
            Math.ceil(props.souarNotMahfoudha?.length / wordsPerPage)
          }
          onClick={() => props.setCurrentPage(props.currentPage + 1)}
        >
          التالي
        </button>
      </div>
    );
  } else if (props.souarNotMahfoudha?.length === 0) {
    return (
      <div>
        <p className="noSouarAvailable">لم أنهي حفظ السورة الأولى بعد</p>
      </div>
    );
  }
};

export default NextPrevNotMahfoudha;
