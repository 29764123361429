let allSouar = [
  "الفاتحة",
  "البقرة",
  "آل عمران",
  "النساء",
  "المائدة",
  "الأنعام",
  "الأعراف",
  "الأنفال",
  "التوبة",
  "يونس",
  "هود",
  "يوسف",
  "الرعد",
  "إبراهيم",
  "الحجر",
  "النحل",
  "الإسراء",
  "الكهف",
  "مريم",
  "طه",
  "الأنبياء",
  "الحج",
  "المؤمنون",
  "النور",
  "الفرقان",
  "الشعراء",
  "النمل",
  "القصص",
  "العنكبوت",
  "الروم",
  "لقمان",
  "السجدة",
  "الأحزاب",
  "سبأ",
  "فاطر",
  "يس",
  "الصافات",
  "ص",
  "الزمر",
  "غافر",
  "فصلت",
  "الشورى",
  "الزخرف",
  "الدخان",
  "الجاثية",
  "الأحقاف",
  "محمد",
  "الفتح",
  "الحجرات",
  "ق",
  "الذاريات",
  "الطور",
  "النجم",
  "القمر",
  "الرحمن",
  "الواقعة",
  "الحديد",
  "المجادلة",
  "الحشر",
  "الممتحنة",
  "الصف",
  "الجمعة",
  "المنافقون",
  "التغابن",
  "الطلاق",
  "التحريم",
  "الملك",
  "القلم",
  "الحاقة",
  "المعارج",
  "نوح",
  "الجن",
  "المزمل",
  "المدثر",
  "القيامة",
  "الإنسان",
  "المرسلات",
  "النبأ",
  "النازعات",
  "عبس",
  "التكوير",
  "الإنفطار",
  "المطففين",
  "الإنشقاق",
  "البروج",
  "الطارق",
  "الأعلى",
  "الغاشية",
  "الفجر",
  "البلد",
  "الشمس",
  "الليل",
  "الضحى",
  "الشرح",
  "التين",
  "العلق",
  "القدر",
  "البينة",
  "الزلزلة",
  "العاديات",
  "القارعة",
  "التكاثر",
  "العصر",
  "الهمزة",
  "الفيل",
  "قريش",
  "الماعون",
  "الكوثر",
  "الكافرون",
  "النصر",
  "المسد",
  "الإخلاص",
  "الفلق",
  "الناس",
];

let souarOfEachHezb = [
  {
    60: [
      "الأعلى",
      "الغاشية",
      "الفجر",
      "البلد",
      "الشمس",
      "الليل",
      "الضحى",
      "الشرح",
      "التين",
      "العلق",
      "القدر",
      "البينة",
      "الزلزلة",
      "العاديات",
      "القارعة",
      "التكاثر",
      "العصر",
      "الهمزة",
      "الفيل",
      "قريش",
      "الماعون",
      "الكوثر",
      "الكافرون",
      "النصر",
      "المسد",
      "الإخلاص",
      "الفلق",
      "الناس",
    ],
  },
  {
    59: [
      "النبأ",
      "النازعات",
      "عبس",
      "التكوير",
      "الإنفطار",
      "المطففين",
      "الإنشقاق",
      "البروج",
      "الطارق",
    ],
  },
  {
    58: ["الجن", "المزمل", "المدثر", "القيامة", "الإنسان", "المرسلات"],
  },
  {
    57: ["الملك", "القلم", "الحاقة", "المعارج", "نوح"],
  },
  {
    56: ["الجمعة", "المنافقون", "التغابن", "الطلاق", "التحريم"],
  },
  {
    55: ["المجادلة", "الحشر", "الممتحنة", "الصف"],
  },
  {
    54: ["الرحمن", "الواقعة", "الحديد"],
  },
  {
    53: ["الذاريات", "الطور", "النجم", "القمر"],
  },
  {
    52: ["الفتح", "الحجرات", "ق", "الذاريات"],
  },
  {
    51: ["الأحقاف", "محمد", "الفتح"],
  },
  {
    50: ["الزخرف", "الدخان", "الجاثية"],
  },
  {
    49: ["فصلت", "الشورى", "الزخرف"],
  },
  {
    48: ["غافر", "فصلت"],
  },
  {
    47: ["الزمر", "غافر"],
  },
  {
    46: ["الصافات", "ص", "الزمر"],
  },
  {
    45: ["يس", "الصافات"],
  },
  {
    44: ["سبأ", "فاطر", "يس"],
  },
  {
    43: ["الأحزاب", "سبأ"],
  },
  {
    42: ["لقمان", "السجدة", "الأحزاب"],
  },
  {
    41: ["العنكبوت", "الروم", "لقمان"],
  },
  {
    40: ["القصص", "العنكبوت"],
  },
  {
    39: ["النمل", "القصص"],
  },
  {
    38: ["الشعراء", "النمل"],
  },
  {
    37: ["الفرقان", "الشعراء"],
  },
  {
    36: ["النور", "الفرقان"],
  },
  {
    35: ["المؤمنون", "النور"],
  },
  {
    34: ["الحج"],
  },
  {
    33: ["الأنبياء"],
  },
  {
    32: ["طه"],
  },
  {
    31: ["الكهف", "مريم"],
  },
  {
    30: ["الإسراء", "الكهف"],
  },
  {
    29: ["الإسراء"],
  },
  {
    28: ["النحل"],
  },
  {
    27: ["الحجر", "النحل"],
  },
  {
    26: ["الرعد", "إبراهيم"],
  },
  {
    25: ["يوسف", "الرعد"],
  },
  {
    24: ["هود", "يوسف"],
  },
  {
    23: ["هود"],
  },
  {
    22: ["يونس", "هود"],
  },
  {
    21: ["التوبة", "يونس"],
  },
  {
    20: ["التوبة"],
  },
  {
    19: ["الأنفال", "التوبة"],
  },
  {
    18: ["الأعراف", "الأنفال"],
  },
  {
    17: ["الأعراف"],
  },
  {
    16: ["الأعراف"],
  },
  {
    15: ["الأنعام"],
  },
  {
    14: ["الأنعام"],
  },
  {
    13: ["المائدة", "الأنعام"],
  },
  {
    12: ["المائدة"],
  },
  {
    11: ["النساء", "المائدة"],
  },
  {
    10: ["النساء"],
  },
  {
    9: ["النساء"],
  },
  {
    8: ["آل عمران", "النساء"],
  },
  {
    7: ["آل عمران"],
  },
  {
    6: ["آل عمران"],
  },
  {
    5: ["البقرة", "آل عمران"],
  },
  {
    4: ["البقرة"],
  },
  {
    3: ["البقرة"],
  },
  {
    2: ["البقرة"],
  },
  {
    1: ["البقرة", "الفاتحة"],
  },
];

export { allSouar, souarOfEachHezb };
