import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import teacherAuth1 from "../../assets/teacherAuth1.png";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { FaBackward } from "react-icons/fa";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#172153",
    },
    secondary: {
      main: "#f56f46",
    },
  },
});

export default function TeacherAuth() {
  const [secondSelectOptions, setSecondSelectOptions] = React.useState([]);
  const [thirdSelectOptions, setthirdSelectOptions] = React.useState([]);
  const [firstname, setfirstname] = React.useState("");
  const [lastname, setlastname] = React.useState("");
  const [wileya, setwileya] = React.useState("");
  const [mootamdia, setmootamdia] = React.useState("");
  const [jamiia, setjamiia] = React.useState("");
  const [ahzab, setahzab] = React.useState("");
  const [signUpEmail, setsignUpEmail] = React.useState("");
  const [passwordSignUp, setpasswordSignUp] = React.useState("");
  const [confirmePass, setconfirmePass] = React.useState("");
  const [signInEmail, setsignInEmail] = React.useState("");
  const [signInPass, setsignInPass] = React.useState("");
  const [isLoginMode, setIsLoginMode] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const signIn = useSignIn();
  let navigate = useNavigate();

  React.useEffect(() => {
    let options = [];
    if (wileya === "أريانة") {
      options = [
        "أريانة المدينة",
        "حي التضامن",
        "قلعة الأندلس",
        "المنيهلة",
        "رواد",
        "سيدي ثابت",
        "سكرة",
      ];
    } else if (wileya === "باجة") {
      options = [
        "عمدون",
        "باجة الشمالية",
        "باجة الجنوبية",
        "قبلاط",
        "مجاز الباب",
        "نفزة",
        "تبرسق",
        "تستور",
        "تيبار",
      ];
    } else if (wileya === "بن عروس") {
      options = [
        "بن عروس المدينة",
        "بومهل البساتين",
        "المروج",
        "الزهراء",
        "فوشانة",
        "حمام الشط",
        "حمام الأنف",
        "المحمدية",
        "المدينة الجديدة",
        "مقرين",
        "مرناق",
        "رادس",
      ];
    } else if (wileya === "بنزرت") {
      options = [
        "بنزرت المدينة",
        "جومين",
        "العالية",
        "غزالة",
        "ماطر",
        "منزل بورقيبة",
        "منزل جميل",
        "رأس الجبل",
        "سجنان",
        "تينجة",
        "أوتيك",
        "جرزونة",
      ];
    } else if (wileya === "قابس") {
      options = [
        "قابس المدينة",
        "قابس الغربية",
        "قابس الجنوبية",
        "غنوش",
        "الحامة",
        "مارث",
        "مطماطة",
        "مطماطة الجديدة",
        "منزل الحبيب",
        "المطوية",
      ];
    } else if (wileya === "قفصة") {
      options = [
        "بلخير",
        "قفصة الشمالية",
        "قفصة الجنوبية",
        "القطار",
        "القصر",
        "المظيلة",
        "المتلوي",
        "أم العرائس",
        "الرديف",
        "السند",
        "سيدي عيش",
        "سيدي بوبكر",
      ];
    } else if (wileya === "جندوبة") {
      options = [
        "جندوبة المدينة",
        "عين دراهم",
        "بلطة بوعوان",
        "بوسالم",
        "فرنانة",
        "غار الدماء",
        "جندوبة الشمالية",
        "وادي مليز",
        "طبرقة",
      ];
    } else if (wileya === "القيروان") {
      options = [
        "العلا",
        "بوحجلة",
        "الشبيكة",
        "الشراردة",
        "حفوز",
        "حاجب العيون",
        "القيروان الشمالية",
        "القيروان الجنوبية",
        "نصر الله",
        "الوسلاتية",
        "السبيخة",
      ];
    } else if (wileya === "القصرين") {
      options = [
        "العيون",
        "الزهور",
        "فريانة",
        "فوسانة",
        "حاسي الفريد",
        "حيدرة",
        "جدليان",
        "القصرين الشمالية",
        "القصرين الجنوبية",
        "ماجل بلعباس",
        "سبيطلة",
        "سبيبة",
        "تالة",
      ];
    } else if (wileya === "قبلي") {
      options = [
        "دوز الشمالية",
        "دوز الجنوبية",
        "الفوار",
        "قبلي الشمالية",
        "قبلي الجنوبية",
        "سوق الأحد",
      ];
    } else if (wileya === "الكاف") {
      options = [
        "الدهماني",
        "السرس",
        "الجريصة",
        "القلعة الخصبة",
        "قلعة سنان",
        "الكاف الشرقية",
        "الكاف الغربية",
        "القصور",
        "نبر",
        "ساقية سيدي يوسف",
        "تاجروين",
        "الطويرف",
      ];
    } else if (wileya === "المهدية") {
      options = [
        "المهدية المدينة",
        "بومرداس",
        "الشابة",
        "شربان",
        "الجم",
        "هبيرة",
        "قصور الساف",
        "ملولش",
        "أولاد الشامخ",
        "سيدي علوان",
        "السواسي",
        "البرادعة",
      ];
    } else if (wileya === "منوبة") {
      options = [
        "منوبة المدينة",
        "برج العامري",
        "دوار هيشر",
        "البطان",
        "الجديدة",
        "المرناقية",
        "وادي الليل",
        "طبربة",
      ];
    } else if (wileya === "مدنين") {
      options = [
        "بنقردان",
        "بني خداش",
        "جربة عجيم",
        "جربة ميدون",
        "جربة حومة السوق",
        "مدنين الشمالية",
        "مدنين الجنوبية",
        "سيدي مخلوف",
        "جرجيس",
      ];
    } else if (wileya === "المنستير") {
      options = [
        "المنستير المدينة",
        "البقالطة",
        "بنبلة",
        "بني حسان",
        "جمال",
        "قصر هلال",
        "قصيبة المديوني",
        "المكنين",
        "الوردانين",
        "الساحلين",
        "صيادة + لمطة + بوحجر",
        "طبلبة",
        "زرمدين",
      ];
    } else if (wileya === "نابل") {
      options = [
        "نابل المدينة",
        "بني خلاد",
        "بني خيار",
        "بوعرقوب",
        "دار شعبان الفهري",
        "الميدة",
        "قرمبالية",
        "حمام الأغزاز",
        "الحمامات",
        "الهوارية",
        "قليبية",
        "قربة",
        "منزل بوزلفة",
        "منزل تميم",
        "سليمان",
        "تاكلسة",
      ];
    } else if (wileya === "صفاقس") {
      options = [
        "صفاقس المدينة",
        "عقارب",
        "بئر علي بن خليفة",
        "العامرة",
        "الغريبة",
        "الحنشة",
        "جبنيانة",
        "قرقنة",
        "المحرس",
        "منزل شاكر",
        "ساقية الدائر",
        "ساقية الزيت",
        "صفاقس الغربية",
        "صفاقس الجنوبية",
        "الصخيرة",
        "طينة",
      ];
    } else if (wileya === "سيدي بوزيد") {
      options = [
        "بئر الحفي",
        "جلمة",
        "المزونة",
        "المكناسي",
        "منزل بوزيان",
        "أولاد حفوز",
        "الرقاب",
        "السبالة",
        "سيدي علي بن عون",
        "سيدي بوزيد الشرقية",
        "سيدي بوزيد الغربية",
        "السوق الجديد",
      ];
    } else if (wileya === "سليانة") {
      options = [
        "برقو",
        "بوعرادة",
        "العروسة",
        "الكريب",
        "قعفور",
        "كسرى",
        "مكثر",
        "الروحية",
        "سيدي بورويس",
        "سليانة الشمالية",
        "سليانة الجنوبية",
      ];
    } else if (wileya === "سوسة") {
      options = [
        "سوسة المدينة",
        "أكودة",
        "بوفيشة",
        "النفيضة",
        "حمام سوسة",
        "هرقلة",
        "القلعة الكبرى",
        "القلعة الصغرى",
        "كندار",
        "مساكن",
        "سيدي بوعلي",
        "سيدي الهاني",
        "معتمدية سوسة جوهرة",
        "سوسة الرياض",
        "سوسة سيدي عبد الحميد",
        "الزاوية قصيبة الثريات",
      ];
    } else if (wileya === "تطاوين") {
      options = [
        "بئر الأحمر",
        "الذهيبة",
        "غمراسن",
        "رمادة",
        "الصمار",
        "تطاوين الشمالية",
        "تطاوين الجنوبية",
      ];
    } else if (wileya === "توزر") {
      options = ["توزر المدينة", "دقاش", "حزوة", "نفطة", "تمغزة"];
    } else if (wileya === "تونس") {
      options = [
        "تونس المدينة",
        "باب البحر",
        "باب السويقة",
        "باردو",
        "ضفاف البحيرة",
        "قرطاج",
        "حي الخضراء",
        "المنزه",
        "الوردية",
        "حي التحرير",
        "الزهور",
        "الحرايرية",
        "جبل الجلود",
        "الكبارية",
        "حلق الوادي",
        "المرسى (تونس)",
        "الكرم (تونس)",
        "العمران",
        "العمران الأعلى",
        "سيدي البشير",
        "سيدي حسين",
        "السيجومي",
      ];
    } else if (wileya === "زغوان") {
      options = [
        "زغوان المدينة",
        "بئر مشارقة",
        "الفحص",
        "الناظور",
        "صواف",
        "الزريبة",
      ];
    }

    setSecondSelectOptions(options);
  }, [wileya]);

  React.useEffect(() => {
    let jamiiaOptions = [];
    if (mootamdia === "نابل المدينة") {
      jamiiaOptions = ["الجمعية القرآنية المحلية بنابل"];
    } else if (mootamdia === "بني خيار") {
      jamiiaOptions = [
        "الجمعية القرآنية  بالمعمورة",
        "الجمعية القرآنية ببني خيار",
      ].filter((v) => v !== mootamdia); // remove duplicates
    }
    setthirdSelectOptions(jamiiaOptions);
  }, [mootamdia]);

  const toggleFormMode = () => {
    setIsLoginMode((prevMode) => !prevMode);
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const logIn = async (event) => {
    event.preventDefault();
    try {
      const signInEmailTrimmed = signInEmail.trim();
      const signInPassTrimmed = signInPass.trim();

      var user = {
        signInEmail: signInEmail,
        signInPass: signInPass,
      };

      let localErrors = {};
      if (!user.signInEmail) localErrors.signInEmail = "أدخل بريدك الإلكتروني";
      if (!user.signInPass) localErrors.signInPass = "أدخل كلمة السر";

      setErrors(localErrors);

      if (Object.keys(localErrors).length === 0) {
        const response = await axios.post("api/auth/loginTeacher", {
          email: signInEmailTrimmed,
          password: signInPassTrimmed,
        });

        const { token } = response.data;
        if (token) {
          signIn({
            token: token,
            expiresIn: 3600, // one hour
            tokenType: "Bearer",
            authState: response.data,
          });
          navigate("/hometeacher", { replace: true });
          toast.success("Welcome Back", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          console.log("Authentication failed:", response.data.error);
        }
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        console.log(err.response.data.error);
      } else {
        console.log("An error occurred:", err.message);
      }
    }
  };

  const signUp = (event) => {
    event.preventDefault();

    var user = {
      firstName: firstname,
      lastName: lastname,
      wileya: wileya,
      mootamdia: mootamdia,
      jamiia: jamiia,
      ahzab: ahzab,
      email: signUpEmail,
      password: passwordSignUp,
      confirmePass: confirmePass,
    };

    let localErrors = {};
    if (!user.firstName) localErrors.firstName = "أدخل اسمك";
    if (!user.lastName) localErrors.lastName = "أدخل لقبك";
    if (!user.email) localErrors.emailUp = "أدخل بريدك الإلكتروني";
    if (!user.password) localErrors.passwordUp = "أدخل كلمة السر";
    if (!user.confirmePass) localErrors.confirmePass = "أدخل كلمة السر";
    if (!user.wileya) localErrors.wileya = "أدخل الولاية";
    if (!user.mootamdia) localErrors.mootamdia = "أدخل المعتمدية";
    if (!user.jamiia) localErrors.jamiia = "أدخل الجمعية القرآنية";
    if (!user.ahzab) localErrors.ahzab = "أدخل عدد الأحزاب";

    setErrors(localErrors);

    let samePassword = false;

    if (
      typeof user.password === "string" &&
      typeof user.confirmePass === "string"
    ) {
      const trimmedPassword = user.password.trim();
      const trimmedConfirmPass = user.confirmePass.trim();

      if (trimmedPassword === trimmedConfirmPass) {
        samePassword = true;
      } else {
        toast.error("Passwords not the same", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error("Invalid password data", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (user.password && user.password.length < 6) {
      toast.error("Passwords too short", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (user.confirmePass && user.confirmePass.length < 6) {
      toast.error("Passwords too short", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!emailRegex.test(signUpEmail) && signUpEmail.length > 0) {
      toast.error("Wrong Email Format", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    var newUser = {
      firstName: firstname,
      lastName: lastname,
      wileya: wileya,
      mootamdia: mootamdia,
      jamiia: jamiia,
      ahzab: ahzab,
      email: signUpEmail,
      password: passwordSignUp,
    };

    if (
      Object.keys(localErrors).length === 0 &&
      samePassword === true &&
      emailRegex.test(signUpEmail)
    ) {
      axios
        .post("api/auth/signupTeacher", newUser)
        .then(({ data }) => {
          toast.success("user created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          toggleFormMode();
        })
        .catch((err) => {
          console.log("Error object:", err);
        });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${teacherAuth1})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "#fcf6ed",
            backgroundSize: "70%",
            backgroundPosition: "center",
            display: { xs: "none", sm: "block" }, // hide on mobile view
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Box}
          elevation={6}
          square="true"
        >
          <form className="form">
            <Link to="/">
              <button className="insha2-hisab">
                الصفحة السابقة
                <FaBackward />
              </button>
            </Link>
            {isLoginMode ? (
              <div>
                {/* Login form */}
                <p className="title">تسجيل الدخول كمعلم</p>
                <p className="message">
                  سجّل الآن كمعلم للقرآن الكريم و تمتع بخدماتنا في مراقبة
                  تلاميذك من حيث الحفظ و المراجعة{" "}
                </p>
                <label>
                  <span className="teacherFormLabels">البريد الالكتروني</span>
                  <input
                    onChange={(e) => setsignInEmail(e.target.value)}
                    required=""
                    placeholder=""
                    type="email"
                    className="input"
                    id="teacherAuthInput"
                  />
                  {errors.signInEmail && (
                    <p className="errorMsg">{errors.signInEmail}</p>
                  )}
                </label>

                <label>
                  <span className="teacherFormLabels">كلمة المرور</span>
                  <input
                    onChange={(e) => setsignInPass(e.target.value)}
                    required=""
                    placeholder=""
                    type={showPassword ? "text" : "password"}
                    className="input"
                    id="teacherAuthInput"
                  />
                  <span
                    className="password-toggle"
                    onClick={handlePasswordVisibility}
                  >
                    {showPassword ? <RiEyeCloseFill /> : <RiEyeFill />}
                  </span>
                  {errors.signInPass && (
                    <p className="errorMsg">{errors.signInPass}</p>
                  )}
                </label>

                <button onClick={logIn} className="submit" id="marginLeft">
                  تسجيل الدخول
                </button>
                <div className="flex" id="marginTop">
                  <p className="message">ليس لديك حساب؟</p>
                  <button className="insha2-hisab" onClick={toggleFormMode}>
                    انشاء حساب جديد
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className="title">انشاء حساب جديد</p>
                <p className="signin">
                  {" "}
                  هل لديك حساب بالفعل؟{" "}
                  <a href="#signin" onClick={toggleFormMode}>
                    تسجيل الدخول
                  </a>{" "}
                </p>
                <p className="message">
                  سجّل الآن كمعلم للقرآن الكريم و تمتع بخدماتنا في مراقبة
                  تلاميذك من حيث الحفظ و المراجعة{" "}
                </p>
                <div className="flex">
                  <label>
                    <span className="teacherFormLabels">الاسم</span>
                    <input
                      onChange={(e) => setfirstname(e.target.value)}
                      required=""
                      placeholder=""
                      type="text"
                      className="input"
                    />
                    {errors.firstName && (
                      <p className="errorMsg">{errors.firstName}</p>
                    )}
                  </label>

                  <label>
                    <span className="teacherFormLabels">اللقب</span>
                    <input
                      onChange={(e) => setlastname(e.target.value)}
                      required=""
                      placeholder=""
                      type="text"
                      className="input"
                    />
                    {errors.lastName && (
                      <p className="errorMsg">{errors.lastName}</p>
                    )}
                  </label>
                </div>

                <div className="flex">
                  <label>
                    <span className="teacherFormLabels">الولاية</span>

                    <select
                      className="input"
                      required
                      onChange={(e) => setwileya(e.target.value)}
                    >
                      <option value="">إختر الولاية</option>
                      <option value="أريانة">أريانة</option>
                      <option value="باجة">باجة</option>
                      <option value=" بن عروس"> بن عروس</option>
                      <option value="بنزرت">بنزرت</option>
                      <option value="تطاوين">تطاوين</option>
                      <option value="توزر">توزر</option>
                      <option value="تونس">تونس</option>
                      <option value="جندوبة">جندوبة</option>
                      <option value="زغوان">زغوان</option>
                      <option value="سليانة">سليانة</option>
                      <option value="سوسة">سوسة</option>
                      <option value="سيدي بوزيد">سيدي بوزيد</option>
                      <option value="صفاقس">صفاقس</option>
                      <option value="قابس">قابس</option>
                      <option value="قبلي">قبلي</option>
                      <option value="القصرين">القصرين</option>
                      <option value="قفصة">قفصة</option>
                      <option value="القيروان">القيروان</option>
                      <option value="الكاف">الكاف</option>
                      <option value="مدنين">مدنين</option>
                      <option value="المنستير">المنستير</option>
                      <option value="منوبة">منوبة</option>
                      <option value="المهدية">المهدية</option>
                      <option value="نابل">نابل</option>
                    </select>
                    {errors.wileya && (
                      <p className="errorMsg">{errors.wileya}</p>
                    )}
                  </label>

                  <label>
                    <span className="teacherFormLabels">المعتمدية</span>
                    <select
                      className="input"
                      onChange={(e) => setmootamdia(e.target.value)}
                    >
                      <option value="">إختر المعتمدية</option>
                      {secondSelectOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {errors.mootamdia && (
                      <p className="errorMsg">{errors.mootamdia}</p>
                    )}
                  </label>
                </div>

                <label>
                  <span className="teacherFormLabels">الجمعية القرآنية</span>
                  <select
                    className="input"
                    id="teacherAuthInput"
                    onChange={(e) => setjamiia(e.target.value)}
                  >
                    <option value="">إختر الجمعية القرآنية</option>
                    {thirdSelectOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {errors.jamiia && <p className="errorMsg">{errors.jamiia}</p>}
                </label>

                <div className="flex">
                  <label>
                    <span className="teacherFormLabels">البريد الالكتروني</span>
                    <input
                      onChange={(e) => setsignUpEmail(e.target.value)}
                      required=""
                      placeholder=""
                      type="email"
                      className="input"
                      id="emailfield"
                    />
                    {errors.emailUp && (
                      <p className="errorMsg">{errors.emailUp}</p>
                    )}
                  </label>

                  <label>
                    <span className="teacherFormLabels">
                      عدد الأحزاب المحفوظة
                    </span>
                    <input
                      onChange={(e) => setahzab(e.target.value)}
                      required=""
                      placeholder=""
                      type="number"
                      max={60}
                      min={2}
                      className="input"
                    />
                    {errors.ahzab && <p className="errorMsg">{errors.ahzab}</p>}
                  </label>
                </div>

                <div className="flex">
                  <label>
                    <span className="teacherFormLabels"> كلمة المرور</span>
                    <input
                      onChange={(e) => setpasswordSignUp(e.target.value)}
                      required=""
                      placeholder=""
                      type={showPassword ? "text" : "password"}
                      className="input"
                    />
                    <span
                      className="password-toggle"
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? <RiEyeCloseFill /> : <RiEyeFill />}
                    </span>
                    {errors.passwordUp && (
                      <p className="errorMsg">{errors.passwordUp}</p>
                    )}
                  </label>

                  <label>
                    <span className="teacherFormLabels">إعادة كلمة المرور</span>
                    <input
                      onChange={(e) => setconfirmePass(e.target.value)}
                      required=""
                      placeholder=""
                      type={showPassword ? "text" : "password"}
                      className="input"
                    />
                    <span
                      className="password-toggle"
                      onClick={handlePasswordVisibility}
                    >
                      {showPassword ? <RiEyeCloseFill /> : <RiEyeFill />}
                    </span>
                    {errors.confirmePass && (
                      <p className="errorMsg">{errors.confirmePass}</p>
                    )}
                  </label>
                </div>

                <div id="idfelx">
                  <div className="subBtnTeacher">
                    <button onClick={signUp} className="submit">
                      انشاء حساب
                    </button>
                  </div>
                  <p className="signin">
                    {" "}
                    هل لديك حساب بالفعل؟{" "}
                    <a href="#signin" onClick={toggleFormMode}>
                      تسجيل الدخول
                    </a>{" "}
                  </p>
                </div>
              </div>
            )}
          </form>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
