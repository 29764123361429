import React, { useEffect, useState } from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import Spinner from "../../Student/Spinner";
import "./TeacherDashboard.css";

const TeacherDashboard = () => {
  let todayDate = new Date().toLocaleDateString("en-GB");
  const userAuth = useAuthUser();

  const [status, setstatus] = useState("loading");
  const [error, setError] = useState(null);
  const [actualWerdValue, setactualWerdValue] = useState("");
  const [isFlipped, setIsFlipped] = useState(false);
  const [backContent, setBackContent] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [teacherData, setteacherData] = useState(null);

  const id = userAuth()._id;
  const userId = id;

  useEffect(() => {
    // Reset `cardSide-${userId}` when a new day starts
    const interval = setInterval(() => {
      const now = new Date();
      const today =
        now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate();

      if (localStorage.getItem("lastResetDate") !== today) {
        handleFlipBack();
        localStorage.setItem("lastResetDate", today);
      }
    }, 60000); // Check once a minute

    const savedSide = localStorage.getItem(`cardSide-${userId}`);
    if (savedSide === "backOne") {
      setBackContent("نعم قرأت وردي و الحمد لله");
      setIsFlipped(true);
    } else if (savedSide === "backTwo") {
      setBackContent("لا للأسف لم أقرأ وردي بعد");
      setIsFlipped(true);
    } else {
      setIsFlipped(false);
    }

    // Clear the interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [userId]);

  useEffect(() => {
    let isCancelled = false;

    if (status === "loading") {
      const id = userAuth()._id;

      axios
        .get(`api/teacher/findOne/${id}`)
        .then(({ data }) => {
          if (!isCancelled) {
            setteacherData(data);
            setstatus("loaded");
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setError(error);
            console.log(error);
            setstatus("error"); // Adjust the status accordingly in case of an error
          }
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [status, userAuth]); // Add userAuth to the dependencies

  const changeWerdOfToday = (werdValue, inputValue) => {
    if (
      (actualWerdValue === "no" && werdValue === "yes") ||
      (actualWerdValue === "yes" && werdValue === "no") ||
      (actualWerdValue === "" && werdValue === "yes") ||
      (actualWerdValue === "" && werdValue === "no")
    ) {
      const id = userAuth()._id;
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const nbreOfNothings = [6, 2, 2, 5, 0, 3, 5, 1, 4, 6, 2, 4]; // don't forget this !
      // nbre of nothings in the beginning of each month
      const d = new Date();
      let currentMonth = months[d.getMonth()];
      let currDayOfMonth = new Date().getDate();
      let monthIndex = months.indexOf(currentMonth);
      let nothingIndex = nbreOfNothings[monthIndex];
      let valueNeeded = currDayOfMonth + nothingIndex;
      if (valueNeeded <= 7) {
        let dayIndex = valueNeeded - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 1, dayIndex);
      } else if (valueNeeded >= 7 && valueNeeded <= 14) {
        let dayIndex = valueNeeded - 7 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 2, dayIndex);
      } else if (valueNeeded >= 14 && valueNeeded <= 21) {
        let dayIndex = valueNeeded - 7 * 2 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 3, dayIndex);
      } else if (valueNeeded >= 21 && valueNeeded <= 28) {
        let dayIndex = valueNeeded - 7 * 3 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 4, dayIndex);
      } else if (valueNeeded >= 28 && valueNeeded <= 35) {
        let dayIndex = valueNeeded - 7 * 4 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 5, dayIndex);
      } else {
        let dayIndex = valueNeeded - 7 * 5 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 6, dayIndex);
      }
    }
  };
  const handleUpdate = (
    id,
    newValue,
    inputValue,
    werdIndex,
    monthIndex,
    weekNbre,
    dayIndex
  ) => {
    axios
      .put(`api/teacher/updateWerd/${id}`, {
        newValue,
        inputValue,
        werdIndex,
        monthIndex,
        weekNbre,
        dayIndex,
      })
      .then((res) => {
        if (newValue === "yes") {
          setactualWerdValue("yes");
          localStorage.setItem("werdOfToday", "yes");
          toast.success("ثبتكم الله", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if (newValue === "no") {
          setactualWerdValue("no");
          localStorage.setItem("werdOfToday", "no");
          toast.error("لا تنسى وردك رجاء", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
  function handleShowBackOne() {
    changeWerdOfToday("yes", inputValue);
    setBackContent("نعم قرأت وردي و الحمد لله");
    setIsFlipped(true);
    localStorage.setItem(`cardSide-${userId}`, "backOne");
  }

  function handleShowBackTwo() {
    changeWerdOfToday("no");
    setBackContent("لا للأسف لم أقرأ وردي بعد");
    setIsFlipped(true);
    localStorage.setItem(`cardSide-${userId}`, "backTwo");
  }

  const handleShowBackOneAndSaveWerd = () => {
    handleShowBackOne();
  };

  function handleFlipBack() {
    setBackContent("");
    setIsFlipped(false);
    localStorage.removeItem(`cardSide-${userId}`);
  }

  const handleSave = () => {
    setShowInput(true);
    handleFlipBack();
  };

  if (status === "loading") {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  } else {
    return (
      <div>
        <div className="container">
          <div className="row rowDash">
            <motion.div
              initial={{ x: 0, opacity: 0 }}
              whileInView={{ x: [-20, 0], opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="col-xl-4 col-md-6 col-xxl-6 test"
            >
              <div className={`card-container ${isFlipped ? "flipped" : ""}`}>
                <div className="card-front">
                  <h4>هل قرأت وردك اليوم ؟</h4>
                  <h5>({todayDate})</h5>
                  <br />
                  <div className="dashBtns">
                    <button className="btnDashYes" onClick={handleSave}>
                      نعم
                    </button>
                    <button className="btnDashNo" onClick={handleShowBackTwo}>
                      ليس بعد
                    </button>
                  </div>
                  {showInput && (
                    <div className="input-container">
                      <h5>حدد النصيب الذي قرأته</h5>
                      <div className="container">
                        <div className="row rowDash">
                          <div className="col-md-6 col-6">
                            <button
                              className="btnDashYes nasibWerdBtn"
                              onClick={handleShowBackOneAndSaveWerd}
                            >
                              حفظ
                            </button>
                          </div>
                          <div className="col-md-6 col-6">
                            <input
                              className="inputWerd"
                              type="text"
                              placeholder="مثال حزب 44 و حزب 45 "
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-back">
                  <div
                    className={`back-content ${
                      backContent === "نعم قرأت وردي و الحمد لله"
                        ? "back-content-back-one"
                        : backContent === "لا للأسف لم أقرأ وردي بعد"
                        ? "back-content-back-two"
                        : ""
                    }`}
                  >
                    {backContent}
                  </div>
                  <button
                    className="backBtn changeWerdStatusBtn"
                    onClick={handleFlipBack}
                  >
                    تغيير حالة الورد
                  </button>
                </div>
              </div>
            </motion.div>
            <div className="col-xl-4 col-md-6 col-xxl-6 dashDivs">
              <br></br>
              <h4 className="QuraniOrangeColor2" id="jamiaName">
                {teacherData.firstName + " " + teacherData.lastName}
              </h4>

              <h3 id="jamiaName">{teacherData.jamiia}</h3>
              <p className="homeParagraphs">بارك الله سعيكم و نفع الله بكم</p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="teacherCard" id="card3">
                    <h4>عدد المجموعات </h4>
                    <h1>{teacherData.majmouaat.length}</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="teacherCard" id="card2">
                    <h4>عدد التلاميذ </h4>
                    <h1>{teacherData.students.length}</h1>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="teacherCard" id="card1">
                    <h4>عدد الأحزاب </h4>
                    <h1>{teacherData.ahzab}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TeacherDashboard;
