import React from "react";
import { useState } from "react";
import { FaStar } from "react-icons/fa";

const StarRating = () => {
  const [rating, setRating] = useState(0);
  const totalStars = 5;

  function RatingDisplay() {
    return (
      <div>
        {rating}/{totalStars}
      </div>
    );
  }
  return (
    <>
      <div className="qurani-feedback-rating-container">
        {[...Array(totalStars)].map((star, index) => {
          const ratingValue = index + 1;
          return (
            <label key={index}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => setRating(ratingValue)}
              />
              <FaStar
                className="star-icon"
                color={ratingValue <= rating ? "#f56f46" : "#172153"}
              />
            </label>
          );
        })}
      </div>
      <RatingDisplay />
    </>
  );
};

export default StarRating;
