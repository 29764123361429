import React, { useState } from "react";
import { allSouar, souarOfEachHezb } from "./Helper";
import { useDispatch } from "react-redux";
import { setHefdhDirection } from "../../../app/features/hefdhDirectionSlice";
import { setcurrHezb } from "../../../app/features/currHezbSlice";
import { useAuthUser } from "react-auth-kit";
import axios from "axios";

const HowToContinueHefdh = (props) => {
  const [currentHezb, setcurrentHezb] = useState(0);
  const user = useAuthUser();
  const dispatch = useDispatch();

  const handleHefdhDirection = (e) => {
    dispatch(setHefdhDirection(e.target.value));
    saveHefdhDirection(e.target.value);
  };
  const handleCurrentHezb = (e) => {
    setcurrentHezb(e.target.value);
    dispatch(setcurrHezb(e.target.value));
  };
  const saveHefdhDirection = (hefdhDirection) => {
    const id = user()._id;
    let data = { hefdhDirection, currentHezb };
    axios
      .post(`api/user/updateHefdh/${id}`, data)
      .then((res) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  function getSurahsByHezb(number) {
    // Find the object in the array that has the given number as a key
    const hezb = souarOfEachHezb.find((item) => {
      return Object.keys(item)[0] === String(number);
    });

    // If no such object is found, return an empty string
    if (!hezb) {
      return "";
    }

    // Return the value of the object as a comma-separated string
    return Object.values(hezb)[0].join(", ");
  }

  const indexToSouraName = (index) => {
    return allSouar.at(index);
  };

  if (
    indexToSouraName(props.souraOne) !== "الناس" &&
    indexToSouraName(props.souraOne) !== "الفاتحة" &&
    indexToSouraName(props.souraOne) !== "البقرة" &&
    indexToSouraName(props.souraTwo) !== "الناس" &&
    indexToSouraName(props.souraTwo) !== "الفاتحة" &&
    indexToSouraName(props.souraTwo) !== "البقرة" &&
    props.souraOne > props.souraTwo
  ) {
    return (
      <div>
        <h6 className="StepParagraphs">
          هل تريد أن تحفظ من سورة {indexToSouraName(props.souraTwo - 1)} إلى
          سورة الفاتحة
          <div></div>
          أم من سورة {indexToSouraName(props.souraOne + 1)} إلى سورة الناس ؟
        </h6>

        <select
          className="inputRegistration"
          id="floatingInputEmail"
          onChange={handleHefdhDirection}
        >
          <option value=""> إختر </option>
          <option
            value={` من سورة ${indexToSouraName(
              props.souraTwo - 1
            )} إلى سورة الفاتحة`}
          >
            من سورة {indexToSouraName(props.souraTwo - 1)} إلى سورة الفاتحة
          </option>
          <option
            value={` من سورة ${indexToSouraName(
              props.souraOne + 1
            )} إلى سورة الناس`}
          >
            {" "}
            من سورة {indexToSouraName(props.souraOne + 1)} إلى سورة الناس
          </option>
        </select>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  } else if (
    indexToSouraName(props.souraOne) !== "الناس" &&
    indexToSouraName(props.souraOne) !== "الفاتحة" &&
    indexToSouraName(props.souraOne) !== "البقرة" &&
    indexToSouraName(props.souraTwo) !== "الناس" &&
    indexToSouraName(props.souraTwo) !== "الفاتحة" &&
    indexToSouraName(props.souraTwo) !== "البقرة" &&
    props.souraOne < props.souraTwo
  ) {
    return (
      <div>
        <h6 className="StepParagraphs">
          هل تريد أن تحفظ من سورة {indexToSouraName(props.souraOne - 1)} إلى
          سورة الفاتحة
          <div></div>
          أم من سورة {indexToSouraName(props.souraTwo + 1)} إلى سورة الناس ؟
        </h6>

        <select
          className="inputRegistration"
          id="floatingInputEmail"
          onChange={handleHefdhDirection}
        >
          <option value=""> إختر </option>
          <option
            value={` من سورة ${indexToSouraName(
              props.souraOne - 1
            )} إلى سورة الفاتحة`}
          >
            من سورة {indexToSouraName(props.souraOne - 1)} إلى سورة الفاتحة
          </option>
          <option
            value={` من سورة ${indexToSouraName(
              props.souraTwo + 1
            )} إلى سورة الناس`}
          >
            {" "}
            من سورة {indexToSouraName(props.souraTwo + 1)} إلى سورة الناس
          </option>
        </select>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  } else if (
    indexToSouraName(props.souraOne) !== "الناس" &&
    indexToSouraName(props.souraTwo) === "الناس"
  ) {
    saveHefdhDirection("إلى سورة الفاتحة");
    return (
      <div>
        <h6 className="StepParagraphs">
          سوف تبدأ الحفظ من سورة {indexToSouraName(props.souraOne - 1)} إن شاء
          الله إلى سورة الفاتحة (إلى سورة البقرة)
          {/* <hr /> */}
        </h6>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  } else if (
    indexToSouraName(props.souraOne) === "الناس" &&
    indexToSouraName(props.souraTwo) !== "الناس"
  ) {
    saveHefdhDirection("إلى سورة الفاتحة");
    return (
      <div>
        <h6 className="StepParagraphs">
          سوف تبدأ الحفظ من سورة {indexToSouraName(props.souraTwo - 1)} إن شاء
          الله إلى سورة الفاتحة (إلى سورة البقرة)
          {/* <hr /> */}
        </h6>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  } else if (
    (indexToSouraName(props.souraOne) === "الفاتحة" &&
      indexToSouraName(props.souraTwo) !== "الفاتحة") ||
    (indexToSouraName(props.souraOne) !== "البقرة" &&
      indexToSouraName(props.souraTwo) === "البقرة")
  ) {
    saveHefdhDirection("إلى سورة الناس");
    return (
      <div>
        <h6 className="StepParagraphs">
          سوف تبدأ الحفظ من سورة {indexToSouraName(props.souraOne + 2)} إن شاء
          الله إلى سورة الناس
          {/* <hr /> */}
        </h6>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  } else if (
    (indexToSouraName(props.souraOne) !== "الفاتحة" &&
      indexToSouraName(props.souraTwo) === "الفاتحة") ||
    (indexToSouraName(props.souraOne) === "البقرة" &&
      indexToSouraName(props.souraTwo) !== "البقرة")
  ) {
    saveHefdhDirection("إلى سورة الناس");
    return (
      <div>
        <h6 className="StepParagraphs">
          سوف تبدأ الحفظ من سورة {indexToSouraName(props.souraOne + 1)} إن شاء
          الله إلى سورة الناس
          {/* <hr /> */}
        </h6>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  } else if (
    (indexToSouraName(props.souraOne) === "الفاتحة" &&
      indexToSouraName(props.souraTwo) !== "الفاتحة") ||
    (indexToSouraName(props.souraOne) === "البقرة" &&
      indexToSouraName(props.souraTwo) !== "البقرة")
  ) {
    saveHefdhDirection("إلى سورة الناس");
    return (
      <div>
        <h6 className="StepParagraphs">
          سوف تبدأ الحفظ من سورة {indexToSouraName(props.souraTwo + 1)} إن شاء
          الله إلى سورة الناس
          {/* <hr /> */}
        </h6>
        <div className="currHezbGridParent">
          <div>
            <h6 className="homeParagraphs currHezbGridChild">
              يعني أنك سوف تبدأ حفظ الحزب عدد
            </h6>
          </div>
          <div className="currHezbGridChild2">
            <input
              className="currHezbInput"
              type="number"
              placeholder={`${60 - parseInt(props.ahzeb)} أو ${
                1 + parseInt(props.ahzeb)
              }`}
              min={1}
              max={60}
              onChange={handleCurrentHezb}
            />
          </div>
        </div>
        <span className="redText HezbHefdh">
          سوف تبدأ حفظ :{" "}
          <span className="souarCurrHezb">{getSurahsByHezb(currentHezb)}</span>
        </span>
      </div>
    );
  }
};

export default HowToContinueHefdh;
