import React from "react";
import { useSelector } from "react-redux";
import DashboardWithHefdh from "./DashboardWithHefdh";
import DashboardWithoutHefdh from "./DashboardWithoutHefdh";

const GeneralDashboard = () => {
  const user = useSelector((state) => state.user);

  if (user.isHefdhActive === false) {
    return <DashboardWithoutHefdh />;
  } else {
    return <DashboardWithHefdh />;
  }
};

export default GeneralDashboard;
