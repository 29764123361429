import React, { useEffect, useState } from "react";
import HowToContinueHefdh from "./HowToContinueHefdh";
import "./Hefdh.css";
import { allSouar } from "./Helper";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const FormHefdhSteps = () => {
  // I am getting hefdhDirection value from Redux - I can get it from DB
  // work was done before saving the value in the DB
  const hefdhDirection = useSelector((state) => state.hefdhDirection);
  const currHezb = useSelector((state) => state.currHezb);
  const user = useAuthUser();
  const [stepToDisplay, setstepToDisplay] = useState("stepOne");
  const [souraOne, setsouraOne] = useState("");
  const [souraTwo, setsouraTwo] = useState("");
  const [ahzeb, setahzeb] = useState(0);
  const [allSouarEdited, setallSouarEdited] = useState([]);
  const [hefdhPerWeek, sethefdhPerWeek] = useState("");
  const [currentHezb, setcurrentHezb] = useState(0);
  const [hefdhDirectionFS, sethefdhDirectionFS] = useState("");
  const [souarNotMahfoudha, setsouarNotMahfoudha] = useState([]);
  const [souarMahfoudha, setsouarMahfoudha] = useState([]);

  useEffect(() => {
    const surahs = allSouar.map((surah, index) => ({
      label: surah,
      value: index + 1,
    }));
    setallSouarEdited(surahs);
    if (hefdhDirectionFS === "من سورة البقرة إلى سورة الناس") {
      setsouarNotMahfoudha(allSouar);
      setcurrentHezb(1);
    } else if (hefdhDirectionFS === "من سورة الناس إلى سورة البقرة") {
      let reversedAllSouar = allSouar.reverse();
      setsouarNotMahfoudha(reversedAllSouar);
      setcurrentHezb(60);
    }

    if (
      indexToSouraName(souraOne) !== "الناس" &&
      indexToSouraName(souraTwo) === "الناس"
    ) {
      let caseOne = allSouar.slice(souraOne);
      setsouarMahfoudha(caseOne);

      let caseTwo = allSouar
        .filter((value) => !caseOne.includes(value))
        .reverse();
      setsouarNotMahfoudha(caseTwo);
    } else if (
      indexToSouraName(souraOne) === "الناس" &&
      indexToSouraName(souraTwo) !== "الناس"
    ) {
      let caseOne = allSouar.slice(souraTwo);
      setsouarMahfoudha(caseOne);

      let caseTwo = allSouar
        .filter((value) => !caseOne.includes(value))
        .reverse();
      setsouarNotMahfoudha(caseTwo);
    } else if (
      (indexToSouraName(souraOne) === "الفاتحة" &&
        indexToSouraName(souraTwo) !== "الفاتحة") ||
      (indexToSouraName(souraOne) !== "البقرة" &&
        indexToSouraName(souraTwo) === "البقرة")
    ) {
      let caseOne = allSouar.slice(souraOne + 2);
      setsouarNotMahfoudha(caseOne);

      let caseTwo = allSouar
        .filter((value) => !caseOne.includes(value))
        .reverse();
      setsouarMahfoudha(caseTwo);
    } else if (
      (indexToSouraName(souraOne) !== "الفاتحة" &&
        indexToSouraName(souraTwo) === "الفاتحة") ||
      (indexToSouraName(souraOne) === "البقرة" &&
        indexToSouraName(souraTwo) !== "البقرة")
    ) {
      let caseOne = allSouar.slice(souraOne + 1);
      setsouarNotMahfoudha(caseOne);

      let caseTwo = allSouar
        .filter((value) => !caseOne.includes(value))
        .reverse();
      setsouarMahfoudha(caseTwo);
    } else if (
      (indexToSouraName(souraOne) === "الفاتحة" &&
        indexToSouraName(souraTwo) !== "الفاتحة") ||
      (indexToSouraName(souraOne) === "البقرة" &&
        indexToSouraName(souraTwo) !== "البقرة")
    ) {
      let caseOne = allSouar.slice(souraTwo + 1);
      setsouarNotMahfoudha(caseOne);

      let caseTwo = allSouar
        .filter((value) => !caseOne.includes(value))
        .reverse();
      setsouarMahfoudha(caseTwo);
    } else if (
      indexToSouraName(souraOne) !== "الناس" &&
      indexToSouraName(souraOne) !== "الفاتحة" &&
      indexToSouraName(souraOne) !== "البقرة" &&
      indexToSouraName(souraTwo) !== "الناس" &&
      indexToSouraName(souraTwo) !== "الفاتحة" &&
      indexToSouraName(souraTwo) !== "البقرة" &&
      souraOne > souraTwo
    ) {
      let notMahfoudhaOne = [];
      let notMahfoudhaTwo = [];
      for (
        let i = allSouar.indexOf(indexToSouraName(souraTwo - 1));
        i >= 0;
        i--
      ) {
        notMahfoudhaOne.push(allSouar[i]);
      }
      for (
        let i = allSouar.length - 1;
        i >= allSouar.indexOf(indexToSouraName(souraOne + 1));
        i--
      ) {
        notMahfoudhaTwo.push(allSouar[i]);
      }

      if (hefdhDirection.includes("إلى سورة الناس")) {
        let notMahfoudhaOneAndTwo = notMahfoudhaTwo
          .reverse()
          .concat(notMahfoudhaOne);
        setsouarNotMahfoudha(notMahfoudhaOneAndTwo);

        let mahfoudha = allSouar.filter(
          (element) => !notMahfoudhaOneAndTwo.includes(element)
        );
        setsouarMahfoudha(mahfoudha);
      } else if (hefdhDirection.includes("إلى سورة الفاتحة")) {
        let notMahfoudhaOneAndTwo = notMahfoudhaOne.concat(
          notMahfoudhaTwo.reverse()
        );
        setsouarNotMahfoudha(notMahfoudhaOneAndTwo);

        let mahfoudha = allSouar.filter(
          (element) => !notMahfoudhaOneAndTwo.includes(element)
        );
        setsouarMahfoudha(mahfoudha);
      }
    } else if (
      indexToSouraName(souraOne) !== "الناس" &&
      indexToSouraName(souraOne) !== "الفاتحة" &&
      indexToSouraName(souraOne) !== "البقرة" &&
      indexToSouraName(souraTwo) !== "الناس" &&
      indexToSouraName(souraTwo) !== "الفاتحة" &&
      indexToSouraName(souraTwo) !== "البقرة" &&
      souraOne < souraTwo
    ) {
      let notMahfoudhaOne = [];
      let notMahfoudhaTwo = [];
      for (
        let i = allSouar.indexOf(indexToSouraName(souraOne - 1));
        i >= 0;
        i--
      ) {
        notMahfoudhaOne.push(allSouar[i]);
      }
      for (
        let i = allSouar.length - 1;
        i >= allSouar.indexOf(indexToSouraName(souraTwo + 1));
        i--
      ) {
        notMahfoudhaTwo.push(allSouar[i]);
      }

      if (hefdhDirection.includes("إلى سورة الناس")) {
        let notMahfoudhaOneAndTwo = notMahfoudhaTwo
          .reverse()
          .concat(notMahfoudhaOne);
        setsouarNotMahfoudha(notMahfoudhaOneAndTwo);

        let mahfoudha = allSouar.filter(
          (element) => !notMahfoudhaOneAndTwo.includes(element)
        );
        setsouarMahfoudha(mahfoudha);
      } else if (hefdhDirection.includes("إلى سورة الفاتحة")) {
        let notMahfoudhaOneAndTwo = notMahfoudhaOne.concat(
          notMahfoudhaTwo.reverse()
        );
        setsouarNotMahfoudha(notMahfoudhaOneAndTwo);

        let mahfoudha = allSouar.filter(
          (element) => !notMahfoudhaOneAndTwo.includes(element)
        );
        setsouarMahfoudha(mahfoudha);
      }
    }
  }, [hefdhDirectionFS, souraOne, souraTwo, hefdhDirection]);

  const startFromScratch = () => {
    setstepToDisplay("stepThree");
  };

  const continueHefdh = () => {
    setstepToDisplay("stepTwo");
  };

  const backToPreviousStep = () => {
    setstepToDisplay("stepOne");
  };

  const indexToSouraName = (index) => {
    return allSouar.at(index);
  };

  function hefdhDeadlineFn(startDate, pagesPerWeek) {
    // Split the start date into day, month, and year components
    const [day, month, year] = startDate.split("/");

    // Create a new Date object with the components in the correct order (month - 1 because months are zero-based)
    const startDateObj = new Date(`${month}/${day}/${year}`);

    // Calculate the number of weeks required to memorize 10 pages
    const numWeeks = Math.ceil(10 / pagesPerWeek);

    // Calculate the finish date by adding the number of weeks to the start date
    const finishDate = new Date(
      startDateObj.getTime() + numWeeks * 7 * 24 * 60 * 60 * 1000
    );

    // Format the finish date as a string with the desired format 'dd/mm/yyyy'
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const finishDateString = finishDate.toLocaleDateString("en-GB", options);

    return finishDateString;
  }

  const startHefdhWithUs = () => {
    const id = user()._id;
    const today = new Date();
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const dateStartingHefdh = today.toLocaleDateString("en-GB", options);
    let hefdhDeadline = hefdhDeadlineFn(dateStartingHefdh, hefdhPerWeek);
    const data = {
      hefdhPerWeek,
      souarNotMahfoudha,
      currentHezb,
    };
    let hezb = currentHezb;
    const dataSouarWithUs = {
      hezb,
      dateStartingHefdh,
      hefdhDeadline,
    };
    if (
      data.hefdhPerWeek === "" ||
      hefdhDirectionFS === "" ||
      data.currentHezb === 0 ||
      data.currentHezb === ""
    ) {
      toast.error("الرجاء ملأ كل الخانات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (data.hefdhPerWeek !== "" && hefdhDirectionFS !== "") {
      axios
        .post(`api/user/updateHefdh/${id}`, data)
        .then(() => {
          return axios.post(
            `api/user/fillSouarMahfoudhaWithUsOne/${id}`,
            dataSouarWithUs
          );
        })
        .then((res) => {
          toast.success("تم حفظ المعلومات", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setstepToDisplay("stepFour");
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  const alreadyStartedHefdh = () => {
    const id = user()._id;
    const today = new Date();
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const dateStartingHefdh = today.toLocaleDateString("en-GB", options);
    let hefdhDeadline = hefdhDeadlineFn(dateStartingHefdh, hefdhPerWeek);
    const data = {
      hefdhPerWeek,
      souarNotMahfoudha,
      souarMahfoudha,
      ahzeb,
      currHezb,
    };
    let hezb = currHezb;
    const dataSouarWithUs = {
      hezb,
      dateStartingHefdh,
      hefdhDeadline,
    };
    if (
      data.hefdhPerWeek === "" ||
      ahzeb === "" ||
      data.currHezb === 0 ||
      data.currHezb === ""
    ) {
      toast.error("الرجاء ملأ كل الخانات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (data.hefdhPerWeek !== "" && ahzeb !== "") {
      axios
        .post(`api/user/updateHefdh/${id}`, data)
        .then(() => {
          return axios.post(
            `api/user/fillSouarMahfoudhaWithUsOne/${id}`,
            dataSouarWithUs
          );
        })
        .then((res) => {
          toast.success("تم حفظ المعلومات", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setstepToDisplay("stepFour");
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.response.data.error, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  const saveHefdhDirectionFS = (hefdhDirection) => {
    const id = user()._id;
    let currentHezb = 60;
    let hezb = currentHezb;
    if (hefdhDirection === "من سورة البقرة إلى سورة الناس") {
      currentHezb = 1;
    }
    let data = { hefdhDirection, currentHezb };
    let dataSouarWithUs = { hezb };
    axios
      .post(`api/user/updateHefdh/${id}`, data)
      .then(() => {
        return axios.post(
          `api/user/fillSouarMahfoudhaWithUsOne/${id}`,
          dataSouarWithUs
        );
      })
      .then((res) => {
        sethefdhDirectionFS(hefdhDirection);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleRefresh = () => {
    window.location.reload();
  };

  if (stepToDisplay === "stepOne") {
    return (
      <div className="stepOne">
        <h4 className="homeParagraphs">
          هل لديك نصيب تحفظه وتريد أن تواصل الحفظ من حيث وصلت ؟
        </h4>
        <button className="registrationBtn" onClick={continueHefdh}>
          نعم
        </button>
        <button className="registrationBtn" onClick={startFromScratch}>
          لا
        </button>
        <hr />
        <p className="homeParagraphs">
          لو كان حفظك القديم غير جيد و يحتاج مراجعة أو السور التي تحفظها غير
          مسترسلة، الأحسن أن تبدأ من الصفر{" "}
        </p>
      </div>
    );
  } else if (stepToDisplay === "stepTwo") {
    return (
      <div className="stepOne">
        <button className="backword" onClick={backToPreviousStep}>
          رجوع
        </button>
        <h6 className="homeParagraphs">
          كم حزباً تحفظ حفظاً جيداً ؟
          <span className="redText">سور المحفوظ يجب أن تكون مسترسلة</span>
        </h6>
        <input
          type="number"
          max={60}
          min={1}
          className="inputRegistration"
          placeholder="أدخل عدداً"
          onChange={(e) => {
            setahzeb(e.target.value);
          }}
        />
        <h6 className="homeParagraphs" id="marginTop">
          حدد بداية و نهاية هذه الأحزاب
        </h6>
        <div className="container">
          <div className="row">
            <div className="col-md-6 orderOne">
              <h6 className="hSix" id="hSixId">
                إلى سورة
              </h6>

              <select
                className="inputRegistration"
                value={allSouarEdited.value}
                onChange={(e) => setsouraTwo(e.target.value - 1)}
              >
                <option value="">إختر سورة</option>
                {allSouarEdited.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 orderTwo">
              <h6 className="hSix" id="hSixId">
                من سورة
              </h6>

              <select
                className="inputRegistration"
                value={allSouarEdited.value}
                onChange={(e) => setsouraOne(e.target.value - 1)}
              >
                <option value="">إختر سورة</option>
                {allSouarEdited.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {
          <HowToContinueHefdh
            souraOne={souraOne}
            souraTwo={souraTwo}
            ahzeb={ahzeb}
          />
        }

        <h6 className="hSix">كم تستطيع أن تحفظ من صفحة في الاسبوع ؟</h6>
        <select
          className="inputRegistration"
          id="floatingInputEmail"
          onChange={(e) => sethefdhPerWeek(e.target.value)}
        >
          <option value=""> إختر عدد الصفحات </option>
          <option value="1">صفحة </option>
          <option value="2 ">صفحتين </option>
          <option value="2.5">صفحتين و نصف</option>
          <option value="3">ثلاث صفحات</option>
          <option value="4">أربع صفحات</option>
          <option value="5">خمسة صفحات</option>
          <option value="6">ست صفحات</option>
        </select>
        <button className="registrationBtn" onClick={alreadyStartedHefdh}>
          حفظ المعطيات
        </button>
      </div>
    );
  } else if (stepToDisplay === "stepThree") {
    return (
      <div className="stepOne">
        <button className="backword" onClick={backToPreviousStep}>
          رجوع
        </button>
        <h3 className="homeParagraphs"> هل تريد أن تبدأ الحفظ</h3>
        <select
          className="inputRegistration"
          id="selectFromScratsh"
          onChange={(e) => saveHefdhDirectionFS(e.target.value)}
        >
          <option value=""> إختر الاتجاه </option>
          <option value="من سورة الناس إلى سورة البقرة">
            من سورة الناس إلى سورة البقرة
          </option>
          <option value="من سورة البقرة إلى سورة الناس">
            من سورة البقرة إلى سورة الناس
          </option>
        </select>
        <h6 className="hSix">كم تستطيع أن تحفظ من صفحة في الاسبوع ؟</h6>
        <select
          className="inputRegistration"
          id="selectFromScratsh"
          onChange={(e) => sethefdhPerWeek(e.target.value)}
        >
          <option value=""> إختر عدد الصفحات </option>
          <option value="1">صفحة </option>
          <option value="2 ">صفحتين </option>
          <option value="2.5">صفحتين و نصف</option>
          <option value="3">ثلاث صفحات</option>
          <option value="4">أربع صفحات</option>
          <option value="5">خمسة صفحات</option>
          <option value="6">ست صفحات</option>
        </select>
        <button className="registrationBtn" onClick={startHefdhWithUs}>
          حفظ المعطيات
        </button>
        <hr />
        <p className="homeParagraphs">
          حسب العديد من تجارب المشايخ و القراء ثبت أن الحفظ بدءًا من سورة الناس
          أكثر فعاليةً من البدء بسورة البقرة
        </p>
      </div>
    );
  } else if (stepToDisplay === "stepFour") {
    return (
      <div className="stepOne">
        <h1 className="homeParagraphs">الصفحة بحاجة إلى تحديث</h1>
        <hr />
        <button id="refreshBtn" className="nextPrevBtn" onClick={handleRefresh}>
          تحديث
        </button>
      </div>
    );
  }
};

export default FormHefdhSteps;
