import React from "react";
import FormHefdhSteps from "../Hefdh/FormHefdhSteps";

const UpdateHefdh = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-10 col-xl-9 mx-auto">
          <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
            <div className="card-img-left d-none d-md-flex"></div>
            <div className="card-body p-4 p-sm-5">
              <FormHefdhSteps />
              <hr className="my-4" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateHefdh;
