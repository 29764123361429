import React, { useEffect, useState } from "react";
import "./HomeTeacher.css";
import TeacherWerd from "./Werd/TeacherWerd";
import Students from "./Students/Students";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearUser } from "../../app/features/userSlice";
import TeacherDashboard from "./Dashboard/TeacherDashboard";
import Ikhtibar from "./Ikhtibar/Ikhtibar";
import Majmouaat from "./Majmouaat/Majmouaat";
import TeacherSettings from "./Settings/TeacherSettings";

const HomeTeacher = () => {
  const user = useSelector((state) => state.user);
  const userAuth = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(null);
  const [currentDisplayedComponent, setcurrentDisplayedComponent] =
    useState("dashboard");

  useEffect(() => {
    setcurrentDisplayedComponent("dashboard");
  }, []);

  const logOut = () => {
    dispatch(clearUser());
    signOut();
    navigate("/teacherauth");
  };

  function changeView() {
    if (currentDisplayedComponent === "dashboard") {
      return <TeacherDashboard />;
    } else if (currentDisplayedComponent === "werd") {
      return <TeacherWerd />;
    } else if (currentDisplayedComponent === "Students") {
      return <Students />;
    } else if (currentDisplayedComponent === "ikhtibar") {
      return <Ikhtibar />;
    } else if (currentDisplayedComponent === "majmouaat") {
      return <Majmouaat />;
    } else if (currentDisplayedComponent === "settings") {
      return <TeacherSettings />;
    }
  }

  return (
    <div className="sideNav">
      <nav className="menu menuTeacher" tabIndex="0">
        <div className="smartphone-menu-trigger"></div>
        <header className="avatar">
          <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="" />
          <p className="LoggedInUserEmail">
            {userAuth().firstName + " " + userAuth().lastName}
          </p>
        </header>
        <ul>
          <li
            tabIndex="0"
            id={activeSection === "dashboard" ? "activeSection" : ""}
            className="icon-dashboard"
            onClick={() => {
              setcurrentDisplayedComponent("dashboard");
              setActiveSection("dashboard");
            }}
          >
            <span>الصفحة الرئيسية</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "werd" ? "activeSection" : ""}
            className="icon-werd"
            onClick={() => {
              setcurrentDisplayedComponent("werd");
              setActiveSection("werd");
            }}
          >
            <span>الورد اليومي</span>
          </li>

          <li
            tabIndex="0"
            id={activeSection === "Students" ? "activeSection" : ""}
            className="icon-students"
            onClick={() => {
              setcurrentDisplayedComponent("Students");
              setActiveSection("Students");
            }}
          >
            <span>التلاميذ</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "majmouaat" ? "activeSection" : ""}
            className="icon-groups"
            onClick={() => {
              setcurrentDisplayedComponent("majmouaat");
              setActiveSection("majmouaat");
            }}
          >
            <span>المجموعات/الأقسام</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "ikhtibar" ? "activeSection" : ""}
            className="icon-exams"
            onClick={() => {
              setcurrentDisplayedComponent("ikhtibar");
              setActiveSection("ikhtibar");
            }}
          >
            <span>إختبارات </span>
          </li>

          <li
            tabIndex="0"
            id={activeSection === "settings" ? "activeSection" : ""}
            className="icon-settings"
            onClick={() => {
              setcurrentDisplayedComponent("settings");
              setActiveSection("settings");
            }}
          >
            <span>الإعدادات</span>
          </li>
          <li tabIndex="0" className="icon-logout" onClick={logOut}>
            <span>خروج</span>
          </li>
        </ul>
      </nav>
      <main>{changeView()}</main>
    </div>
  );
};

export default HomeTeacher;
