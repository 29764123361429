import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import "./Students.css";
import CheckHefdh from "./CheckHefdh";
import CheckWerd from "./CheckWerd";
import { fetchUserById } from "../../../app/features/userSlice";
import { useAuthUser } from "react-auth-kit";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../Student/Spinner";

const Students = () => {
  const userAuth = useAuthUser();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const memoizedTeacherName = useMemo(
    () => `${userAuth().firstName} ${userAuth().lastName}`,
    [userAuth]
  );

  const [students, setStudents] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState("loading");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [view, setView] = useState("students");
  const [teacherData, setTeacherData] = useState({});
  const [dataFetched, setdataFetched] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("كل المجموعات");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = userAuth()._id;
        const response = await axios.get(`/api/teacher/findOne/${id}`);
        const data = response.data;
        setTeacherData(data);
        setStatus("loaded");
        setdataFetched(true);
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    if (!dataFetched) {
      fetchData();
    }
  }, [userAuth, dataFetched]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (!user || !user._id) {
          const id = userAuth()._id;
          dispatch(fetchUserById(id));
        } else {
          setStatus("loaded");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user, dispatch, userAuth]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get("/api/user/findMany", {
          params: {
            teacherName: memoizedTeacherName,
          },
        });
        setStudents(response.data);
        setStatus("loaded");
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, [memoizedTeacherName]);

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleGroupChange = (event) => {
    setSelectedGroup(event.target.value);
  };

  const filteredStudents = useMemo(
    () =>
      students.filter((student) => {
        const fullName = `${student.firstName} ${student.lastName}`;
        return (
          fullName.toLowerCase().includes(searchInput.toLowerCase()) &&
          (selectedGroup === "كل المجموعات" ||
            student.majmouaa.includes(selectedGroup))
        );
      }),
    [students, searchInput, selectedGroup]
  );

  const checkWerd = (data) => {
    setView("checkWerd");
    setSelectedStudent(data);
  };

  const checkHefdh = (data) => {
    setView("checkHefdh");
    setSelectedStudent(data);
  };

  const changeToStudentsView = () => {
    setView("students");
  };

  // const filteredStudents = useMemo(
  //   () =>
  //     students.filter((student) => {
  //       const fullName = `${student.firstName} ${student.lastName}`;
  //       return fullName.toLowerCase().includes(searchInput.toLowerCase());
  //     }),
  //   [students, searchInput]
  // );

  if (status === "loading") {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (students.length === 0) {
    return (
      <div>
        <h3 className="loading">مازال تلاميذك لم يسجلوا في منصة تعاهدوا بعد</h3>
      </div>
    );
  } else {
    if (view === "students") {
      return (
        <div className="all-students">
          <div className="flex">
            <div className="searchBox">
              <input
                className="searchInput"
                type="text"
                name=""
                placeholder="ابحث عن تلميذ"
                onChange={handleSearchInputChange}
              />
              <button className="searchButton" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29"
                  height="29"
                  viewBox="0 0 29 29"
                  fill="none"
                >
                  <g clipPath="url(#clip0_2_17)">
                    <g filter="url(#filter0_d_2_17)">
                      <path
                        d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        shapeRendering="crispEdges"
                      ></path>
                    </g>
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_2_17"
                      x="-0.418549"
                      y="3.70435"
                      width="29.7139"
                      height="29.7139"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                      ></feFlood>
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      ></feColorMatrix>
                      <feOffset dy="4"></feOffset>
                      <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                      <feComposite in2="hardAlpha" operator="out"></feComposite>
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      ></feColorMatrix>
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2_17"
                      ></feBlend>
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2_17"
                        result="shape"
                      ></feBlend>
                    </filter>
                    <clipPath id="clip0_2_17">
                      <rect
                        width="28.0702"
                        height="28.0702"
                        fill="white"
                        transform="translate(0.403503 0.526367)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div className="filter-dropdown">
              <select
                value={selectedGroup}
                onChange={handleGroupChange}
                className="filterMajmouaat"
              >
                <option value="كل المجموعات">كل المجموعات</option>
                {teacherData &&
                  teacherData.majmouaat &&
                  teacherData.majmouaat.length > 0 &&
                  teacherData.majmouaat.map((majmouaa) => (
                    <option key={majmouaa} value={majmouaa}>
                      {majmouaa}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="container">
            <div className="row">
              {filteredStudents.map((student) => (
                <div className="col-lg-4 col-md-6 col-sm-12" key={student._id}>
                  <div className="card mb-3 singleProfile">
                    <img
                      src={
                        student.profileImage ||
                        process.env.PUBLIC_URL + "/images/user.png"
                      }
                      alt=""
                      className="card-img-top profileImage"
                    />
                    <div className="card-body2">
                      <h3 className="ahzab">{student.ahzeb} حزب </h3>
                      <h3 className="studentName">
                        {student.firstName} {student.lastName}
                      </h3>
                      <div
                        className="StudentDetailsBtn"
                        onClick={() => checkWerd(student)}
                        id="StudentDetailsBtn"
                      >
                        مراقبة الورد
                      </div>
                      <div
                        className="StudentDetailsBtn"
                        onClick={() => checkHefdh(student)}
                      >
                        مراقبة الحفظ
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (view === "checkHefdh") {
      return (
        <CheckHefdh
          changeToStudentsView={changeToStudentsView}
          studentData={selectedStudent}
        />
      );
    } else {
      return (
        <CheckWerd
          changeToStudentsView={changeToStudentsView}
          studentData={selectedStudent}
        />
      );
    }
  }
};

export default Students;
