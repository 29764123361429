import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jamiiaAuth from "../../assets/jamiiaAuth.png";
import { toast } from "react-toastify";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { FaBackward } from "react-icons/fa";
import "./AddJamiia.css";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#172153",
    },
    secondary: {
      main: "#f56f46",
    },
  },
});

export default function AddJamiia() {
  const [secondSelectOptions, setSecondSelectOptions] = React.useState([]);
  const [jamiiaName, setjamiiaName] = React.useState("");
  const [wileya, setwileya] = React.useState("");
  const [mootamdia, setmootamdia] = React.useState("");
  const [responsableName, setresponsableName] = React.useState("");
  const [email, setemail] = React.useState("");
  const [phoneNumber, setphoneNumber] = React.useState("");
  const [errors, setErrors] = React.useState({});

  const signIn = useSignIn();
  let navigate = useNavigate();

  React.useEffect(() => {
    let options = [];
    if (wileya === "أريانة") {
      options = [
        "أريانة المدينة",
        "حي التضامن",
        "قلعة الأندلس",
        "المنيهلة",
        "رواد",
        "سيدي ثابت",
        "سكرة",
      ];
    } else if (wileya === "باجة") {
      options = [
        "عمدون",
        "باجة الشمالية",
        "باجة الجنوبية",
        "قبلاط",
        "مجاز الباب",
        "نفزة",
        "تبرسق",
        "تستور",
        "تيبار",
      ];
    } else if (wileya === "بن عروس") {
      options = [
        "بن عروس المدينة",
        "بومهل البساتين",
        "المروج",
        "الزهراء",
        "فوشانة",
        "حمام الشط",
        "حمام الأنف",
        "المحمدية",
        "المدينة الجديدة",
        "مقرين",
        "مرناق",
        "رادس",
      ];
    } else if (wileya === "بنزرت") {
      options = [
        "بنزرت المدينة",
        "جومين",
        "العالية",
        "غزالة",
        "ماطر",
        "منزل بورقيبة",
        "منزل جميل",
        "رأس الجبل",
        "سجنان",
        "تينجة",
        "أوتيك",
        "جرزونة",
      ];
    } else if (wileya === "قابس") {
      options = [
        "قابس المدينة",
        "قابس الغربية",
        "قابس الجنوبية",
        "غنوش",
        "الحامة",
        "مارث",
        "مطماطة",
        "مطماطة الجديدة",
        "منزل الحبيب",
        "المطوية",
      ];
    } else if (wileya === "قفصة") {
      options = [
        "بلخير",
        "قفصة الشمالية",
        "قفصة الجنوبية",
        "القطار",
        "القصر",
        "المظيلة",
        "المتلوي",
        "أم العرائس",
        "الرديف",
        "السند",
        "سيدي عيش",
        "سيدي بوبكر",
      ];
    } else if (wileya === "جندوبة") {
      options = [
        "جندوبة المدينة",
        "عين دراهم",
        "بلطة بوعوان",
        "بوسالم",
        "فرنانة",
        "غار الدماء",
        "جندوبة الشمالية",
        "وادي مليز",
        "طبرقة",
      ];
    } else if (wileya === "القيروان") {
      options = [
        "العلا",
        "بوحجلة",
        "الشبيكة",
        "الشراردة",
        "حفوز",
        "حاجب العيون",
        "القيروان الشمالية",
        "القيروان الجنوبية",
        "نصر الله",
        "الوسلاتية",
        "السبيخة",
      ];
    } else if (wileya === "القصرين") {
      options = [
        "العيون",
        "الزهور",
        "فريانة",
        "فوسانة",
        "حاسي الفريد",
        "حيدرة",
        "جدليان",
        "القصرين الشمالية",
        "القصرين الجنوبية",
        "ماجل بلعباس",
        "سبيطلة",
        "سبيبة",
        "تالة",
      ];
    } else if (wileya === "قبلي") {
      options = [
        "دوز الشمالية",
        "دوز الجنوبية",
        "الفوار",
        "قبلي الشمالية",
        "قبلي الجنوبية",
        "سوق الأحد",
      ];
    } else if (wileya === "الكاف") {
      options = [
        "الدهماني",
        "السرس",
        "الجريصة",
        "القلعة الخصبة",
        "قلعة سنان",
        "الكاف الشرقية",
        "الكاف الغربية",
        "القصور",
        "نبر",
        "ساقية سيدي يوسف",
        "تاجروين",
        "الطويرف",
      ];
    } else if (wileya === "المهدية") {
      options = [
        "المهدية المدينة",
        "بومرداس",
        "الشابة",
        "شربان",
        "الجم",
        "هبيرة",
        "قصور الساف",
        "ملولش",
        "أولاد الشامخ",
        "سيدي علوان",
        "السواسي",
        "البرادعة",
      ];
    } else if (wileya === "منوبة") {
      options = [
        "منوبة المدينة",
        "برج العامري",
        "دوار هيشر",
        "البطان",
        "الجديدة",
        "المرناقية",
        "وادي الليل",
        "طبربة",
      ];
    } else if (wileya === "مدنين") {
      options = [
        "بنقردان",
        "بني خداش",
        "جربة عجيم",
        "جربة ميدون",
        "جربة حومة السوق",
        "مدنين الشمالية",
        "مدنين الجنوبية",
        "سيدي مخلوف",
        "جرجيس",
      ];
    } else if (wileya === "المنستير") {
      options = [
        "المنستير المدينة",
        "البقالطة",
        "بنبلة",
        "بني حسان",
        "جمال",
        "قصر هلال",
        "قصيبة المديوني",
        "المكنين",
        "الوردانين",
        "الساحلين",
        "صيادة + لمطة + بوحجر",
        "طبلبة",
        "زرمدين",
      ];
    } else if (wileya === "نابل") {
      options = [
        "نابل المدينة",
        "بني خلاد",
        "بني خيار",
        "بوعرقوب",
        "دار شعبان الفهري",
        "الميدة",
        "قرمبالية",
        "حمام الأغزاز",
        "الحمامات",
        "الهوارية",
        "قليبية",
        "قربة",
        "منزل بوزلفة",
        "منزل تميم",
        "سليمان",
        "تاكلسة",
      ];
    } else if (wileya === "صفاقس") {
      options = [
        "صفاقس المدينة",
        "عقارب",
        "بئر علي بن خليفة",
        "العامرة",
        "الغريبة",
        "الحنشة",
        "جبنيانة",
        "قرقنة",
        "المحرس",
        "منزل شاكر",
        "ساقية الدائر",
        "ساقية الزيت",
        "صفاقس الغربية",
        "صفاقس الجنوبية",
        "الصخيرة",
        "طينة",
      ];
    } else if (wileya === "سيدي بوزيد") {
      options = [
        "بئر الحفي",
        "جلمة",
        "المزونة",
        "المكناسي",
        "منزل بوزيان",
        "أولاد حفوز",
        "الرقاب",
        "السبالة",
        "سيدي علي بن عون",
        "سيدي بوزيد الشرقية",
        "سيدي بوزيد الغربية",
        "السوق الجديد",
      ];
    } else if (wileya === "سليانة") {
      options = [
        "برقو",
        "بوعرادة",
        "العروسة",
        "الكريب",
        "قعفور",
        "كسرى",
        "مكثر",
        "الروحية",
        "سيدي بورويس",
        "سليانة الشمالية",
        "سليانة الجنوبية",
      ];
    } else if (wileya === "سوسة") {
      options = [
        "سوسة المدينة",
        "أكودة",
        "بوفيشة",
        "النفيضة",
        "حمام سوسة",
        "هرقلة",
        "القلعة الكبرى",
        "القلعة الصغرى",
        "كندار",
        "مساكن",
        "سيدي بوعلي",
        "سيدي الهاني",
        "معتمدية سوسة جوهرة",
        "سوسة الرياض",
        "سوسة سيدي عبد الحميد",
        "الزاوية قصيبة الثريات",
      ];
    } else if (wileya === "تطاوين") {
      options = [
        "بئر الأحمر",
        "الذهيبة",
        "غمراسن",
        "رمادة",
        "الصمار",
        "تطاوين الشمالية",
        "تطاوين الجنوبية",
      ];
    } else if (wileya === "توزر") {
      options = ["توزر المدينة", "دقاش", "حزوة", "نفطة", "تمغزة"];
    } else if (wileya === "تونس") {
      options = [
        "تونس المدينة",
        "باب البحر",
        "باب السويقة",
        "باردو",
        "ضفاف البحيرة",
        "قرطاج",
        "حي الخضراء",
        "المنزه",
        "الوردية",
        "حي التحرير",
        "الزهور",
        "الحرايرية",
        "جبل الجلود",
        "الكبارية",
        "حلق الوادي",
        "المرسى (تونس)",
        "الكرم (تونس)",
        "العمران",
        "العمران الأعلى",
        "سيدي البشير",
        "سيدي حسين",
        "السيجومي",
      ];
    } else if (wileya === "زغوان") {
      options = [
        "زغوان المدينة",
        "بئر مشارقة",
        "الفحص",
        "الناظور",
        "صواف",
        "الزريبة",
      ];
    }

    setSecondSelectOptions(options);
  }, [wileya]);

  const signUp = (event) => {
    event.preventDefault();

    var user = {
      wileya: wileya,
      mootamdia: mootamdia,
    };

    let localErrors = {};
    if (!user.jamiia) localErrors.jamiia = "أدخل الجمعية القرآنية";
    if (!user.firstName) localErrors.firstName = "أدخل اسمك";
    if (!user.email) localErrors.emailUp = "أدخل بريدك الإلكتروني";
    if (!user.wileya) localErrors.wileya = "أدخل الولاية";
    if (!user.mootamdia) localErrors.mootamdia = "أدخل المعتمدية";

    setErrors(localErrors);

    let samePassword = false;

    if (
      typeof user.password === "string" &&
      typeof user.confirmePass === "string"
    ) {
      const trimmedPassword = user.password.trim();
      const trimmedConfirmPass = user.confirmePass.trim();

      if (trimmedPassword === trimmedConfirmPass) {
        samePassword = true;
      } else {
        toast.error("Passwords not the same", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } else {
      toast.error("Invalid password data", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (user.password && user.password.length < 6) {
      toast.error("Passwords too short", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    if (user.confirmePass && user.confirmePass.length < 6) {
      toast.error("Passwords too short", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!emailRegex.test(email) && email.length > 0) {
      toast.error("Wrong Email Format", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    var newUser = {
      wileya: wileya,
      mootamdia: mootamdia,
    };

    if (
      Object.keys(localErrors).length === 0 &&
      samePassword === true &&
      emailRegex.test(email)
    ) {
      axios
        .post("api/auth/signupTeacher", newUser)
        .then(({ data }) => {
          toast.success("user created successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        })
        .catch((err) => {
          console.log("Error object:", err);
        });
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${jamiiaAuth})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "#fcf6ed",
            backgroundSize: "70%",
            backgroundPosition: "center",
            display: { xs: "none", sm: "block" }, // hide on mobile view
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Box}
          elevation={6}
          square="true"
        >
          <form className="form">
            <Link to="/">
              <button className="insha2-hisab">
                الصفحة السابقة
                <FaBackward />
              </button>
            </Link>

            <div>
              <p className="title">طلب زيادة جمعية قرآنية</p>
              <p className="message">
                طلب زيادة جمعية قرآنية في منصة تعاهدوا للتمتع بجميع خدماتها{" "}
              </p>

              <label>
                <span className="teacherFormLabels">إسم الجمعية القرآنية</span>

                <input
                  onChange={(e) => setjamiiaName(e.target.value)}
                  required=""
                  placeholder="الإسم كاملاً بالعربية"
                  type="text"
                  className="input"
                  id="teacherAuthInput"
                />
                {errors.jamiia && <p className="errorMsg">{errors.jamiia}</p>}
              </label>

              <div className="flex">
                <label>
                  <span className="teacherFormLabels">الولاية</span>

                  <select
                    className="input"
                    required
                    onChange={(e) => setwileya(e.target.value)}
                  >
                    <option value="">إختر الولاية</option>
                    <option value="أريانة">أريانة</option>
                    <option value="باجة">باجة</option>
                    <option value=" بن عروس"> بن عروس</option>
                    <option value="بنزرت">بنزرت</option>
                    <option value="تطاوين">تطاوين</option>
                    <option value="توزر">توزر</option>
                    <option value="تونس">تونس</option>
                    <option value="جندوبة">جندوبة</option>
                    <option value="زغوان">زغوان</option>
                    <option value="سليانة">سليانة</option>
                    <option value="سوسة">سوسة</option>
                    <option value="سيدي بوزيد">سيدي بوزيد</option>
                    <option value="صفاقس">صفاقس</option>
                    <option value="قابس">قابس</option>
                    <option value="قبلي">قبلي</option>
                    <option value="القصرين">القصرين</option>
                    <option value="قفصة">قفصة</option>
                    <option value="القيروان">القيروان</option>
                    <option value="الكاف">الكاف</option>
                    <option value="مدنين">مدنين</option>
                    <option value="المنستير">المنستير</option>
                    <option value="منوبة">منوبة</option>
                    <option value="المهدية">المهدية</option>
                    <option value="نابل">نابل</option>
                  </select>
                  {errors.wileya && <p className="errorMsg">{errors.wileya}</p>}
                </label>

                <label>
                  <span className="teacherFormLabels">المعتمدية</span>
                  <select
                    className="input"
                    onChange={(e) => setmootamdia(e.target.value)}
                  >
                    <option value="">إختر المعتمدية</option>
                    {secondSelectOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {errors.mootamdia && (
                    <p className="errorMsg">{errors.mootamdia}</p>
                  )}
                </label>
              </div>

              <p className="simpleText">معلومات تتعلق بشخص مسؤول في الجمعية:</p>

              <label>
                <span className="teacherFormLabels">الإسم و اللقب</span>
                <input
                  onChange={(e) => setresponsableName(e.target.value)}
                  required=""
                  placeholder=""
                  type="text"
                  className="input"
                  id="emailfield"
                />
                {errors.firstName && (
                  <p className="errorMsg">{errors.firstName}</p>
                )}
              </label>

              <label>
                <span className="teacherFormLabels">البريد الالكتروني</span>
                <input
                  onChange={(e) => setemail(e.target.value)}
                  required=""
                  placeholder=""
                  type="email"
                  className="input"
                  id="emailfield"
                />
                {errors.emailUp && <p className="errorMsg">{errors.emailUp}</p>}
              </label>

              <label>
                <span className="teacherFormLabels">الهاتف الجوال </span>
                <input
                  onChange={(e) => setphoneNumber(e.target.value)}
                  required=""
                  placeholder=""
                  type="text"
                  className="input"
                  id="emailfield"
                />
                {errors.emailUp && <p className="errorMsg">{errors.emailUp}</p>}
              </label>
            </div>

            <div id="idfelx">
              <div className="subBtnTeacher">
                <button onClick={signUp} className="submit">
                  إرسال الطلب
                </button>
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
