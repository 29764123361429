import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import MonthCalendar from "./MonthCalendar";
import axios from "axios";
import { motion } from "framer-motion";
import Spinner from "../../Student/Spinner";

const TeacherWerd = () => {
  const userAuth = useAuthUser();
  const [status, setstatus] = useState("loading");
  const [error, setError] = useState(null);
  const [teacherData, setteacherData] = useState({});
  const [januaryData, setjanuaryData] = useState({});
  const [febuaryData, setfebuaryData] = useState({});
  const [marchData, setmarchData] = useState({});
  const [aprilData, setaprilData] = useState({});
  const [mayData, setmayData] = useState({});
  const [juneData, setjuneData] = useState({});
  const [julyData, setjulyData] = useState({});
  const [augustData, setaugustData] = useState({});
  const [septemberData, setseptemberData] = useState({});
  const [octoberData, setoctoberData] = useState({});
  const [novemberData, setnovemberData] = useState({});
  const [decemberData, setdecemberData] = useState({});
  const [view, setview] = useState("month");
  const [monthIndexToDisplay, setmonthIndexToDisplay] = useState(0);
  const d = new Date();
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let currentMonth = months[d.getMonth()];
  let monInd = months.indexOf(currentMonth);
  // let monInd = 3;

  useEffect(() => {
    const id = userAuth()._id;
    let isCancelled = false;
    axios
      .get(`api/teacher/findOne/${id}`)
      .then((response) => {
        if (!isCancelled) {
          setjanuaryData(response.data.werd[0][0]);
          setfebuaryData(response.data.werd[0][1]);
          setmarchData(response.data.werd[0][2]);
          setaprilData(response.data.werd[0][3]);
          setmayData(response.data.werd[0][4]);
          setjuneData(response.data.werd[0][5]);
          setjulyData(response.data.werd[0][6]);
          setaugustData(response.data.werd[0][7]);
          setseptemberData(response.data.werd[0][8]);
          setoctoberData(response.data.werd[0][9]);
          setnovemberData(response.data.werd[0][10]);
          setdecemberData(response.data.werd[0][11]);
          setteacherData(response.data);
          setstatus("loaded");
        }
      })
      .catch((error) => {
        if (!isCancelled) {
          setError(error);
          console.log(error);
          setstatus("loading");
        }
      });
    setmonthIndexToDisplay(monInd);
    return () => {
      isCancelled = true;
    };
  }, []);

  let January = "جانفي";
  let February = "فيفري";
  let March = "مارس";
  let April = "أفريل";
  let May = "ماي";
  let June = "جوان";
  let July = "جويلية";
  let August = "أوت";
  let September = "سبتمبر";
  let October = "أكتوبر";
  let November = "نوفمبر";
  let December = "ديسمبر";

  function generateMonthArray(year, month) {
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDayOfWeek = new Date(year, month, 1).getDay();

    const monthArray = Array.from(
      { length: daysInMonth },
      (_, index) => index + 1
    );

    const startEmptyBoxes = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;
    const totalBoxes = 42;
    const endEmptyBoxes = totalBoxes - (startEmptyBoxes + daysInMonth);

    const emptyBoxesArray = Array.from({ length: startEmptyBoxes }, () => "");
    const emptyEndBoxesArray = Array.from({ length: endEmptyBoxes }, () => "");

    return [...emptyBoxesArray, ...monthArray, ...emptyEndBoxesArray];
  }
  // agenerateMonthArray(2023, 3);

  let propsData = [
    {
      data: januaryData,
      days: generateMonthArray(2023, 0),
      monthName: January,
    },
    {
      data: febuaryData,
      days: generateMonthArray(2023, 1),
      monthName: February,
    },
    { data: marchData, days: generateMonthArray(2023, 2), monthName: March },
    { data: aprilData, days: generateMonthArray(2023, 3), monthName: April },
    { data: mayData, days: generateMonthArray(2023, 4), monthName: May },
    { data: juneData, days: generateMonthArray(2023, 5), monthName: June },
    { data: julyData, days: generateMonthArray(2023, 6), monthName: July },
    { data: augustData, days: generateMonthArray(2023, 7), monthName: August },
    {
      data: septemberData,
      days: generateMonthArray(2023, 8),
      monthName: September,
    },
    {
      data: octoberData,
      days: generateMonthArray(2023, 9),
      monthName: October,
    },
    {
      data: novemberData,
      days: generateMonthArray(2023, 10),
      monthName: November,
    },
    {
      data: decemberData,
      days: generateMonthArray(2023, 11),
      monthName: December,
    },
  ];

  const nextMonth = () => {
    const d = new Date();
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let currentMonth = months[d.getMonth()];
    let monInd = months.indexOf(currentMonth);

    let nextIndex = monthIndexToDisplay + 1;
    if (nextIndex <= monInd) {
      setmonthIndexToDisplay(nextIndex);
    }
  };

  const previousMonth = () => {
    let previousIndex = monthIndexToDisplay - 1;
    if (previousIndex >= 0) {
      setmonthIndexToDisplay(previousIndex);
    }
  };

  let filteredArray = propsData.filter((element, index) => index <= monInd);

  let componentList = filteredArray.map((element, index) => (
    <div className="col-xl-4 col-md-6 col-xxl-4 calendarComponent" key={index}>
      <MonthCalendar
        data={element.data}
        days={element.days}
        monthName={element.monthName}
      />
    </div>
  ));

  const monthView = () => {
    setview("month");
  };

  const yearView = () => {
    setview("year");
  };

  if (status === "loading") {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (view === "year") {
    return (
      <div>
        <div>
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ x: [-20, 0], opacity: 1 }}
            transition={{ duration: 0.5 }}
          ></motion.div>
        </div>
        <div className="werdTwoButtons">
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ y: [-30, 0], opacity: 1 }}
            transition={{ duration: 1 }}
            className="wrapper"
          >
            <input type="radio" name="select" id="option-1" defaultChecked />
            <input type="radio" name="select" id="option-2" />
            <label
              onClick={monthView}
              htmlFor="option-1"
              className="option option-1"
            >
              <div className="dot"></div>
              <span>هذا الشهر</span>
            </label>
            <label
              onClick={yearView}
              htmlFor="option-2"
              className="option option-2"
            >
              <div className="dot"></div>
              <span>هذا العام</span>
            </label>
          </motion.div>

          <div className="container">
            <div className="row calendarRow">{componentList}</div>
          </div>
        </div>
      </div>
    );
  } else if (view === "month") {
    return (
      <div>
        <div>
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ x: [-20, 0], opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="testWerd"
          >
            <p>
              يتم تغيير حالة الورد من{" "}
              <span className="dashWerd">الصفحة الرئيسية</span>
            </p>
            <p>
              الخانة <span className="redBG">الحمراء </span>تدل على عدم قراءة
              الورد لذلك اليوم
            </p>
            <p>
              الخانة <span className="greenBG">الخضراء </span>تدل على قراءة
              الورد لذلك اليوم
            </p>
            <p>
              يمكنك النقر على الخانات الخضراء
              <span className="dashWerd"> لرؤية نصيب الورد المقروء</span> في ذلك
              اليوم{" "}
            </p>
          </motion.div>
        </div>
        <div className="werdTwoButtons">
          <motion.div
            initial={{ x: 0, opacity: 0 }}
            whileInView={{ y: [-30, 0], opacity: 1 }}
            transition={{ duration: 1 }}
            className="wrapper"
          >
            <input type="radio" name="select" id="option-1" defaultChecked />
            <input type="radio" name="select" id="option-2" />
            <label
              onClick={monthView}
              htmlFor="option-1"
              className="option option-1"
            >
              <div className="dot"></div>
              <span>هذا الشهر</span>
            </label>
            <label
              onClick={yearView}
              htmlFor="option-2"
              className="option option-2"
            >
              <div className="dot"></div>
              <span>هذا العام</span>
            </label>
          </motion.div>

          <h3 className="werdk">هذا الورد الخاص بك </h3>

          <div className=" calendarComponentMonth">
            <div className="next-prev-btns-pc">
              <button
                className="nextPrevBtn"
                onClick={previousMonth}
                disabled={monthIndexToDisplay <= 0}
              >
                السابق
              </button>
              <button
                disabled={monthIndexToDisplay === monInd}
                className="nextPrevBtn"
                onClick={nextMonth}
              >
                التالي
              </button>
            </div>
            <div className="calendarMonthView">
              <MonthCalendar
                data={propsData[monthIndexToDisplay].data}
                days={propsData[monthIndexToDisplay].days}
                monthName={propsData[monthIndexToDisplay].monthName}
              />
            </div>
            <div className="next-prev-btns-phone">
              <button
                className="nextPrevBtn"
                onClick={previousMonth}
                disabled={monthIndexToDisplay <= 0}
              >
                السابق
              </button>
              <button
                disabled={monthIndexToDisplay === monInd}
                className="nextPrevBtn"
                onClick={nextMonth}
              >
                التالي
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default TeacherWerd;
