import React, { Suspense, useState } from "react";
import Modal from "react-modal";
import Spinner from "../Spinner";
import "./Course.css";

Modal.setAppElement("#root");

const Course = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleButtonClick = () => {
    const url = "https://qalun.com/";
    const win = window.open(url, "_blank");
    win.focus();
  };

  const openModal = (src) => {
    setIsLoading(true);
    setVideoSrc(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setVideoSrc("");
  };

  function handleVideoLoad() {
    setIsLoading(false);
  }

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <div className="qalun flex">
          <div className="qalunPicContainer" onClick={handleButtonClick}>
            <img
              src={process.env.PUBLIC_URL + "/images/qalun.PNG"}
              className="qalunPic"
              alt=""
            />
            <div className="qalunTextOverlay">www.qalun.com</div>
          </div>
          <div>
            <h4 className="qalunText">
              نقدم لكم موقع مهم جداً يحتوي على جميع الدروس النظرية المتعلقة
              برواية قالون عن نافع مع تطبيقات و إختبارات تعليمية{" "}
            </h4>
            <button className="qalunBtn" onClick={handleButtonClick}>
              زيارة الموقع
            </button>
          </div>
        </div>
        <p className="withQuraniText someCorses">بعض الدروس المبسطة</p>
        <div className="container">
          <div className="row videoRow">
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 3 : أحكام النون الساكنة والتنوين (الإدغام)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/pbDXg2kBYN8?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/pbDXg2kBYN8/mqdefault.jpg"
                  alt="video3"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 2 : أحكام النون الساكنة والتنوين (الإظهار الحلقي)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/Fkd0NIjUkQs?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/Fkd0NIjUkQs/mqdefault.jpg"
                  alt="video2"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">الدرس 1 : النون والميم المشددتان</p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/TcrnmAUgOF8?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/TcrnmAUgOF8/mqdefault.jpg"
                  alt="video1"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 6 : أحكام الميم الساكنة (الإخفاء الشفوي)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/MeLqAU9iJpw?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/MeLqAU9iJpw/mqdefault.jpg"
                  alt="video6"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 5 : أحكام النون الساكنة والتنوين (الإخفاء)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/RNXcTbJsWo4?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/RNXcTbJsWo4/mqdefault.jpg"
                  alt="video5"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 4 : أحكام النون الساكنة والتنوين (الإقلاب)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/5EhdiokFlbs?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/5EhdiokFlbs/mqdefault.jpg"
                  alt="video4"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 9 : أحكام المد (المد الواجب المتصل + المد الجائز المنفصل)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/fI28o9WhYxA?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/fI28o9WhYxA/mqdefault.jpg"
                  alt="video9"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">الدرس 8 : أحكام المد (المد الطبيعي)</p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/gTWNPNT5Q0I?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/gTWNPNT5Q0I/mqdefault.jpg"
                  alt="video8"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 7 : أحكام الميم الساكنة (الإدغام الشفوي والإظهار الشفوي )
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/oD4um5dc7Cw?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/oD4um5dc7Cw/mqdefault.jpg"
                  alt="video7"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 12 : أحكام المد (مد الصلة "الصغرى والكبرى)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/I-5nnBlMp0U?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/I-5nnBlMp0U/mqdefault.jpg"
                  alt="video12"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 11 : أحكام المد (المد العارض للسكون)
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/7uteVyNYWZ8?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/7uteVyNYWZ8/mqdefault.jpg"
                  alt="video11"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">الدرس 10 : أحكام المد (المد اللازم )</p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/raRX4lLdJUg?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/raRX4lLdJUg/mqdefault.jpg"
                  alt="video10"
                />
              </button>
            </div>

            <div className="col-md-4 singleVideo">
              <p className="darsNumber">الدرس 15 : حكم القلقة</p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/3rWxKRZ9Q0Y?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/3rWxKRZ9Q0Y/mqdefault.jpg"
                  alt="video15"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 14 : التفخيم والترقيق (الجزء الثاني){" "}
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/OrkCPaAX-po?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/OrkCPaAX-po/mqdefault.jpg"
                  alt="video14"
                />
              </button>
            </div>
            <div className="col-md-4 singleVideo">
              <p className="darsNumber">
                الدرس 13 : التفخيم والترقيق (الجزء الأول){" "}
              </p>
              <button
                onClick={() =>
                  openModal(
                    "https://www.youtube.com/embed/CBOiw1VdPWs?list=PLf4oZdFzpmzosw5Qp7-dL4PQgvDui1Oe-"
                  )
                }
              >
                <img
                  src="https://img.youtube.com/vi/CBOiw1VdPWs/mqdefault.jpg"
                  alt="video13"
                />
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Video Modal"
          className="videoModal"
          overlayClassName="Overlay"
        >
          <button
            type="button"
            className="closeBtn close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">غلق&times;</span>
          </button>
          <div className="video-container">
            {isLoading && <Spinner />}
            <iframe
              width="100%"
              height="500px"
              onLoad={handleVideoLoad}
              src={videoSrc}
              title="Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </Modal>
      </Suspense>
    </div>
  );
};

export default Course;
