import React, { useState } from "react";
import Modal from "react-modal";
import ModalContent from "./ModalContent";
import "./MonthCalendar.css";

const HomeStudent = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [werdSelected, setwerdSelected] = useState("");

  const handleCellClick = (className, werd) => {
    if (className === "yes") {
      setModalIsOpen(true);
      setwerdSelected(werd);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="backgroundColor">
      <div id="calendar">
        <h1 className="monthName">{props.monthName}</h1>
        <table className="calendarTable">
          <thead>
            <tr>
              <td className="hastask">الأحد</td>
              <td className="hastask">السبت</td>
              <td className="hastask">الجمعة</td>
              <td className="hastask">الخميس</td>
              <td className="hastask">الاربعاء</td>
              <td className="hastask">الثلاثاء</td>
              <td className="hastask">الإثنين</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className={props.data.week1[6][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[6][0],
                    props.data.week1[6][1]
                  )
                }
              >
                {props.days[6]}
              </td>
              <td
                className={props.data.week1[5][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[5][0],
                    props.data.week1[5][1]
                  )
                }
              >
                {props.days[5]}
              </td>
              <td
                className={props.data.week1[4][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[4][0],
                    props.data.week1[4][1]
                  )
                }
              >
                {props.days[4]}
              </td>
              <td
                className={props.data.week1[3][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[3][0],
                    props.data.week1[3][1]
                  )
                }
              >
                {props.days[3]}
              </td>
              <td
                className={props.data.week1[2][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[2][0],
                    props.data.week1[2][1]
                  )
                }
              >
                {props.days[2]}
              </td>
              <td
                className={props.data.week1[1][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[1][0],
                    props.data.week1[1][1]
                  )
                }
              >
                {props.days[1]}
              </td>
              <td
                className={props.data.week1[0][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week1[0][0],
                    props.data.week1[0][1]
                  )
                }
              >
                {props.days[0]}
              </td>
            </tr>

            <tr>
              <td
                className={props.data.week2[6][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[6][0],
                    props.data.week2[6][1]
                  )
                }
              >
                {props.days[13]}
              </td>
              <td
                className={props.data.week2[5][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[5][0],
                    props.data.week2[5][1]
                  )
                }
              >
                {props.days[12]}
              </td>
              <td
                className={props.data.week2[4][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[4][0],
                    props.data.week2[4][1]
                  )
                }
              >
                {props.days[11]}
              </td>
              <td
                className={props.data.week2[3][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[3][0],
                    props.data.week2[3][1]
                  )
                }
              >
                {props.days[10]}
              </td>
              <td
                className={props.data.week2[2][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[2][0],
                    props.data.week2[2][1]
                  )
                }
              >
                {props.days[9]}
              </td>
              <td
                className={props.data.week2[1][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[1][0],
                    props.data.week2[1][1]
                  )
                }
              >
                {props.days[8]}
              </td>
              <td
                className={props.data.week2[0][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week2[0][0],
                    props.data.week2[0][1]
                  )
                }
              >
                {props.days[7]}
              </td>
            </tr>

            <tr>
              <td
                className={props.data.week3[6][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[6][0],
                    props.data.week3[6][1]
                  )
                }
              >
                {props.days[20]}
              </td>
              <td
                className={props.data.week3[5][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[5][0],
                    props.data.week3[5][1]
                  )
                }
              >
                {props.days[19]}
              </td>
              <td
                className={props.data.week3[4][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[4][0],
                    props.data.week3[4][1]
                  )
                }
              >
                {props.days[18]}
              </td>
              <td
                className={props.data.week3[3][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[3][0],
                    props.data.week3[3][1]
                  )
                }
              >
                {props.days[17]}
              </td>
              <td
                className={props.data.week3[2][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[2][0],
                    props.data.week3[2][1]
                  )
                }
              >
                {props.days[16]}
              </td>
              <td
                className={props.data.week3[1][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[1][0],
                    props.data.week3[1][1]
                  )
                }
              >
                {props.days[15]}
              </td>
              <td
                className={props.data.week3[0][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week3[0][0],
                    props.data.week3[0][1]
                  )
                }
              >
                {props.days[14]}
              </td>
            </tr>

            <tr>
              <td
                className={props.data.week4[6][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[6][0],
                    props.data.week4[6][1]
                  )
                }
              >
                {props.days[27]}
              </td>
              <td
                className={props.data.week4[5][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[5][0],
                    props.data.week4[5][1]
                  )
                }
              >
                {props.days[26]}
              </td>
              <td
                className={props.data.week4[4][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[4][0],
                    props.data.week4[4][1]
                  )
                }
              >
                {props.days[25]}
              </td>
              <td
                className={props.data.week4[3][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[3][0],
                    props.data.week4[3][1]
                  )
                }
              >
                {props.days[24]}
              </td>
              <td
                className={props.data.week4[2][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[2][0],
                    props.data.week4[2][1]
                  )
                }
              >
                {props.days[23]}
              </td>
              <td
                className={props.data.week4[1][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[1][0],
                    props.data.week4[1][1]
                  )
                }
              >
                {props.days[22]}
              </td>
              <td
                className={props.data.week4[0][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week4[0][0],
                    props.data.week4[0][1]
                  )
                }
              >
                {props.days[21]}
              </td>
            </tr>

            <tr>
              <td
                className={props.data.week5[6][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[6][0],
                    props.data.week5[6][1]
                  )
                }
              >
                {props.days[34]}
              </td>
              <td
                className={props.data.week5[5][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[5][0],
                    props.data.week5[5][1]
                  )
                }
              >
                {props.days[33]}
              </td>
              <td
                className={props.data.week5[4][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[4][0],
                    props.data.week5[4][1]
                  )
                }
              >
                {props.days[32]}
              </td>
              <td
                className={props.data.week5[3][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[3][0],
                    props.data.week5[3][1]
                  )
                }
              >
                {props.days[31]}
              </td>
              <td
                className={props.data.week5[2][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[2][0],
                    props.data.week5[2][1]
                  )
                }
              >
                {props.days[30]}
              </td>
              <td
                className={props.data.week5[1][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[1][0],
                    props.data.week5[1][1]
                  )
                }
              >
                {props.days[29]}
              </td>
              <td
                className={props.data.week5[0][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week5[0][0],
                    props.data.week5[0][1]
                  )
                }
              >
                {props.days[28]}
              </td>
            </tr>

            <tr>
              <td
                className={props.data.week6[6][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[6][0],
                    props.data.week6[6][1]
                  )
                }
              >
                {props.days[41]}
              </td>
              <td
                className={props.data.week6[5][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[5][0],
                    props.data.week6[5][1]
                  )
                }
              >
                {props.days[40]}
              </td>
              <td
                className={props.data.week6[4][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[4][0],
                    props.data.week6[4][1]
                  )
                }
              >
                {props.days[39]}
              </td>
              <td
                className={props.data.week6[3][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[3][0],
                    props.data.week6[3][1]
                  )
                }
              >
                {props.days[38]}
              </td>
              <td
                className={props.data.week6[2][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[2][0],
                    props.data.week6[2][1]
                  )
                }
              >
                {props.days[37]}
              </td>
              <td
                className={props.data.week6[1][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[1][0],
                    props.data.week6[1][1]
                  )
                }
              >
                {props.days[36]}
              </td>
              <td
                className={props.data.week6[0][0]}
                onClick={() =>
                  handleCellClick(
                    props.data.week6[0][0],
                    props.data.week6[0][1]
                  )
                }
              >
                {props.days[35]}
              </td>
            </tr>
          </tbody>
        </table>
        <Modal
          className="werdModal"
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          {/* <button onClick={closeModal}>Close</button> */}
          <button
            type="button"
            className="closeBtnWerd close"
            aria-label="Close"
            onClick={closeModal}
          >
            <span aria-hidden="true">غلق&times;</span>
          </button>
          <ModalContent currentWerd={werdSelected} />
        </Modal>
      </div>
    </div>
  );
};

export default HomeStudent;
