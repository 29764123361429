import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import axios from "axios";
import "./DashboardWithHefdh.css";
import { useAuthUser } from "react-auth-kit";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { fetchUserById } from "../../../app/features/userSlice";
import { souarOfEachHezb } from "../Hefdh/Helper";
import ProgressBar from "./ProgressBar";
import Spinner from "../Spinner";

const DashboardWithHefdh = () => {
  let todayDate = new Date().toLocaleDateString("en-GB");
  const userAuth = useAuthUser();
  const [status, setstatus] = useState("loading");
  const [error, setError] = useState(null);
  const [actualWerdValue, setactualWerdValue] = useState("");
  // const [newCurrHezb, setnewCurrHezb] = useState(60);
  const [isFlipped, setIsFlipped] = useState(false);
  const [backContent, setBackContent] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // const [direction, setdirection] = useState("toFetiha");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const ahzebCompleted = user.ahzeb;
  const progress = (ahzebCompleted / 60) * 100;
  const id = userAuth()._id;
  const userId = id;

  useEffect(() => {
    if (!user || !user._id) {
      // User data not yet available in the store, so fetch it from the API
      const id = userAuth()._id;
      dispatch(fetchUserById(id));
    } else {
      // User data is already in the store, so update the component state with it
      setstatus("loaded");
    }
  }, [user, dispatch, userAuth]);

  useEffect(() => {
    let isCancelled = false;
    if (status === "loading") {
      const id = userAuth()._id;
      axios
        .get(`api/user/findOne/${id}`)
        .then(({ data }) => {
          if (!isCancelled) {
            setstatus("loaded");
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setError(error);
            console.log(error);
            setstatus("loading");
          }
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [status, userAuth]);

  useEffect(() => {
    // Reset `cardSide-${userId}` when a new day starts
    const interval = setInterval(() => {
      const now = new Date();
      const today =
        now.getFullYear() + "-" + now.getMonth() + "-" + now.getDate();

      if (localStorage.getItem("lastResetDate") !== today) {
        handleFlipBack();
        localStorage.setItem("lastResetDate", today);
      }
    }, 60000); // Check once a minute

    const savedSide = localStorage.getItem(`cardSide-${userId}`);
    if (savedSide === "backOne") {
      setBackContent("نعم قرأت وردي و الحمد لله");
      setIsFlipped(true);
    } else if (savedSide === "backTwo") {
      setBackContent("لا للأسف لم أقرأ وردي بعد");
      setIsFlipped(true);
    } else {
      setIsFlipped(false);
    }

    // Clear the interval on component unmount to prevent memory leaks
    return () => clearInterval(interval);
  }, [userId]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function handleShowBackOne() {
    changeWerdOfToday("yes", inputValue);
    setBackContent("نعم قرأت وردي و الحمد لله");
    setIsFlipped(true);
    localStorage.setItem(`cardSide-${userId}`, "backOne");
  }

  function handleShowBackTwo() {
    changeWerdOfToday("no");
    setBackContent("لا للأسف لم أقرأ وردي بعد");
    setIsFlipped(true);
    localStorage.setItem(`cardSide-${userId}`, "backTwo");
  }

  function handleFlipBack() {
    setBackContent("");
    setIsFlipped(false);
    localStorage.removeItem(`cardSide-${userId}`);
  }

  const handleSave = () => {
    setShowInput(true);
    handleFlipBack();
  };

  function getValuesByKey(key) {
    let disSouar = souarOfEachHezb.flatMap((obj) => {
      const values = obj[key];
      if (values) {
        return values;
      } else {
        return [];
      }
    });
    return disSouar;
  }

  function removeAndAdd(array1, array2, array3) {
    // Remove elements of souarToMove from souarNotMahfoudha
    array3.forEach(function (element) {
      let index = array1.indexOf(element);
      if (index !== -1) {
        array1.splice(index, 1);
      }
    });

    // Add elements of souarToMove to the beginning of souarMahfoudha
    array3.forEach(function (element) {
      if (!array2.includes(element)) {
        array2.unshift(element);
      }
    });

    return { array1, array2 };
  }

  function compareDates(date1, date2) {
    var parts1 = date1.split("/");
    var parts2 = date2.split("/");
    var d1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
    var d2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);

    if (d1 <= d2) {
      return "نعم";
    } else {
      return "لا";
    }
  }

  function hefdhDeadlineFn(startDate, pagesPerWeek) {
    // Split the start date into day, month, and year components
    const [day, month, year] = startDate.split("/");

    // Create a new Date object with the components in the correct order (month - 1 because months are zero-based)
    const startDateObj = new Date(`${month}/${day}/${year}`);

    // Calculate the number of weeks required to memorize 10 pages
    const numWeeks = Math.ceil(10 / pagesPerWeek);

    // Calculate the finish date by adding the number of weeks to the start date
    const finishDate = new Date(
      startDateObj.getTime() + numWeeks * 7 * 24 * 60 * 60 * 1000
    );

    // Format the finish date as a string with the desired format 'dd/mm/yyyy'
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const finishDateString = finishDate.toLocaleDateString("en-GB", options);

    return finishDateString;
  }

  const finishCurrHezb = () => {
    let updatedCurrHezb = 0;
    const newSouarNotMahfoudha = Array.isArray(user.souarNotMahfoudha)
      ? [...user.souarNotMahfoudha]
      : [];
    const newSouarMahfoudha = Array.isArray(user.souarMahfoudha)
      ? [...user.souarMahfoudha]
      : [];
    // Move the souar that the user just finished from souarNotMahfoudha to souarMahfoudha
    const souarToMove = getValuesByKey(user.currentHezb);
    removeAndAdd(newSouarNotMahfoudha, newSouarMahfoudha, souarToMove);

    if (user.hefdhDirection?.includes("إلى سورة الناس")) {
      if (user.currentHezb < 60) {
        updatedCurrHezb = user.currentHezb + 1;
        // setnewCurrHezb(updatedCurrHezb);
      } else {
        updatedCurrHezb = 1;
        // setnewCurrHezb(updatedCurrHezb);
      }
    } else if (
      user.hefdhDirection?.includes("إلى سورة الفاتحة") ||
      user.hefdhDirection?.includes("إلى سورة البقرة")
    ) {
      if (user.currentHezb > 1) {
        updatedCurrHezb = user.currentHezb - 1;
        // setnewCurrHezb(updatedCurrHezb);
      } else {
        updatedCurrHezb = 60;
        // setnewCurrHezb(updatedCurrHezb);
      }
    } else {
      toast.error("عدل الحفظ من الإعدادات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }

    const today = new Date();
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    let finishHefdhDatee = today.toLocaleDateString("en-GB", options);
    // let finishHefdhDatee = "17/11/2023";

    let CurrHezbFinished = user.souarMahfoudhaWithUs.find(function (hezb) {
      return hezb.hezb === user.currentHezb;
    });

    let hefdhPerWeek = user.hefdhPerWeek;
    const dateStartingHefdh = today.toLocaleDateString("en-GB", options);
    let currHezbDeadline = CurrHezbFinished
      ? CurrHezbFinished.hefdhDeadline
      : hefdhDeadlineFn(dateStartingHefdh, hefdhPerWeek);
    let isOnTimee = compareDates(finishHefdhDatee, currHezbDeadline);
    let hefdhDeadline = hefdhDeadlineFn(dateStartingHefdh, hefdhPerWeek);

    const data = {
      souarNotMahfoudha: newSouarNotMahfoudha,
      souarMahfoudha: newSouarMahfoudha,
      ahzeb: user.ahzeb,
      currentHezb: updatedCurrHezb,
    };
    const dataSouarWithUs = {
      hezb: updatedCurrHezb,
      finishHefdhDate: finishHefdhDatee,
      hefdhDeadline: hefdhDeadline,
      isOnTime: isOnTimee,
    };

    axios
      .post(`api/user/finishHezb/${id}`, data)
      .then(() => {
        return axios.post(
          `api/user/fillSouarMahfoudhaWithUsTwo/${id}`,
          dataSouarWithUs
        );
      })
      .then((res) => {
        toast.success("تم تحديث المعلومات", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.response.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const changeWerdOfToday = (werdValue, inputValue) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (
      (actualWerdValue === "no" && werdValue === "yes") ||
      (actualWerdValue === "yes" && werdValue === "no") ||
      (actualWerdValue === "" && werdValue === "yes") ||
      (actualWerdValue === "" && werdValue === "no")
    ) {
      const id = userAuth()._id;
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      // const nbreOfNothings = [6, 2, 2, 5, 0, 3, 5, 1, 4, 6, 2, 4];
      const nbreOfNothings = [];
      for (let i = 0; i < 12; i++) {
        // const daysInMonth = new Date(currentYear, i + 1, 0).getDate();
        const firstDayOfWeek = new Date(currentYear, i, 1).getDay();
        const startEmptyBoxes = firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1;
        nbreOfNothings.push(startEmptyBoxes);
      }
      // nbre of nothings is the beginning of each month
      const d = new Date();
      let currentMonth = months[d.getMonth()];
      let currDayOfMonth = new Date().getDate();
      let monthIndex = months.indexOf(currentMonth);
      let nothingIndex = nbreOfNothings[monthIndex];
      let valueNeeded = currDayOfMonth + nothingIndex;
      if (valueNeeded <= 7) {
        let dayIndex = valueNeeded - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 1, dayIndex);
      } else if (valueNeeded >= 7 && valueNeeded <= 14) {
        let dayIndex = valueNeeded - 7 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 2, dayIndex);
      } else if (valueNeeded >= 14 && valueNeeded <= 21) {
        let dayIndex = valueNeeded - 7 * 2 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 3, dayIndex);
      } else if (valueNeeded >= 21 && valueNeeded <= 28) {
        let dayIndex = valueNeeded - 7 * 3 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 4, dayIndex);
      } else if (valueNeeded >= 28 && valueNeeded <= 35) {
        let dayIndex = valueNeeded - 7 * 4 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 5, dayIndex);
      } else {
        let dayIndex = valueNeeded - 7 * 5 - 1;
        handleUpdate(id, werdValue, inputValue, 0, monthIndex, 6, dayIndex);
      }
    }
  };
  const handleUpdate = (
    id,
    newValue,
    inputValue,
    werdIndex,
    monthIndex,
    weekNbre,
    dayIndex
  ) => {
    axios
      .put(`api/user/updateWerd/${id}`, {
        newValue,
        inputValue,
        werdIndex,
        monthIndex,
        weekNbre,
        dayIndex,
      })
      .then((res) => {
        if (newValue === "yes") {
          setactualWerdValue("yes");
          localStorage.setItem("werdOfToday", "yes");
          toast.success("ثبتكم الله", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else if (newValue === "no") {
          setactualWerdValue("no");
          localStorage.setItem("werdOfToday", "no");
          toast.error("لا تنسى وردك رجاء", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const handleShowBackOneAndSaveWerd = () => {
    handleShowBackOne();
  };

  function memorizationTime(numHezb, pagesPerWeek) {
    const totalPages = numHezb * 10;
    const numWeeks = Math.ceil(totalPages / pagesPerWeek);

    const numYears = Math.floor(numWeeks / 52);
    const numMonths = Math.floor((numWeeks % 52) / 4);
    const numWeeksLeft = (numWeeks % 52) % 4;

    const timeArr = [];

    if (numYears > 0) {
      timeArr.push(` ${numYears} سنوات`);
    }

    if (numMonths > 0) {
      timeArr.push(`${numMonths} أشهر`);
    }

    if (numWeeksLeft > 0) {
      timeArr.push(`${numWeeksLeft} أسابيع`);
    }

    return timeArr.join(" و ");
  }

  function formatArray(arr) {
    return arr.join(", ");
  }

  if (status === "loading") {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  } else {
    return (
      <div>
        <div className="container">
          <div className="row rowDash">
            <motion.div
              initial={{ x: 0, opacity: 0 }}
              whileInView={{ x: [-20, 0], opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="col-xl-4 col-md-6 col-xxl-6 test"
            >
              <div className={`card-container ${isFlipped ? "flipped" : ""}`}>
                <div className="card-front">
                  <h4>هل قرأت وردك اليوم ؟</h4>
                  <h5>({todayDate})</h5>
                  <br />
                  <div className="dashBtns">
                    <button className="btnDashYes" onClick={handleSave}>
                      نعم
                    </button>
                    <button className="btnDashNo" onClick={handleShowBackTwo}>
                      ليس بعد
                    </button>
                  </div>
                  {showInput && (
                    <div className="input-container">
                      <h5>حدد النصيب الذي قرأته</h5>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <button
                              className="btnDashYes nasibWerdBtn"
                              onClick={handleShowBackOneAndSaveWerd}
                            >
                              حفظ
                            </button>
                          </div>
                          <div className="col-md-6 col-6">
                            <input
                              className="inputWerd"
                              type="text"
                              placeholder="مثال حزب 44 و حزب 45 "
                              value={inputValue}
                              onChange={(e) => setInputValue(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="card-back">
                  <div
                    className={`back-content ${
                      backContent === "نعم قرأت وردي و الحمد لله"
                        ? "back-content-back-one"
                        : backContent === "لا للأسف لم أقرأ وردي بعد"
                        ? "back-content-back-two"
                        : ""
                    }`}
                  >
                    {backContent}
                  </div>
                  <button
                    className="backBtn changeWerdStatusBtn"
                    onClick={handleFlipBack}
                  >
                    تغيير حالة الورد
                  </button>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: 0, opacity: 0 }}
              whileInView={{ x: [20, 0], opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="col-xl-4 col-md-6 col-xxl-6 dashDivs"
            >
              <h4 className="arabicText">
                هل أتممت حفظ الحزب {user.currentHezb} ؟
              </h4>
              <h6>({formatArray(getValuesByKey(user.currentHezb))})</h6>
              <br></br>
              <div className="dashBtns werdBtns">
                <button
                  onClick={openModal}
                  className="btnDashYes"
                  id="btnDashYesId"
                >
                  نعم
                </button>

                {/* <button onClick={notFinishingHezb} className="btnDashNo">
                  ليس بعد
                </button> */}
              </div>
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Confirmation Modal"
                className="werdModal"
              >
                <h2 className="titleWerdCont">
                  هل أنت متأكد أنك تريد المتابعة؟
                </h2>
                <div className="centerBtns">
                  <button
                    className="modalBtns"
                    id="noBtnModal"
                    onClick={closeModal}
                  >
                    رجوع
                  </button>
                  <button
                    className="modalBtns"
                    id="yesBtnModal"
                    onClick={finishCurrHezb}
                  >
                    نعم{" "}
                  </button>
                </div>
              </Modal>
              <p>بمعدل حفظ {user.hefdhPerWeek} صفحات في الاسبوع</p>
              <p className="secondP">
                فإن حفظ هذا الحزب يجب أن لا يتجاوز تاريخ{" "}
                {hefdhDeadlineFn(
                  user.souarMahfoudhaWithUs[
                    user.souarMahfoudhaWithUs.length - 1
                  ].dateStartingHefdh,
                  user.hefdhPerWeek
                )}
              </p>
            </motion.div>
            <motion.div
              initial={{ x: 0, opacity: 0 }}
              whileInView={{ x: [-20, 0], opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="col-xl-4 col-md-6 col-xxl-6 dashDivs"
            >
              <h3 className="QuraniOrangeColor2">تقدير تاريخ الختم</h3>
              <p>
                لحفظ {60 - user.ahzeb} حزب بمعدل حفظ {user.hefdhPerWeek} صفحات
                في الأسبوع، الختمة ستكون بإذن الله بعد
              </p>
              <h4 className="quraniBlueColor">
                {memorizationTime(60 - user.ahzeb, user.hefdhPerWeek)}
              </h4>
              <p className="grayColor">
                لتقليل المدة عليك بزيادة الحفظ المقرر في الأسبوع
              </p>
            </motion.div>

            <motion.div
              initial={{ x: 0, opacity: 0 }}
              whileInView={{ x: [20, 0], opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="col-xl-4 col-md-6 col-xxl-6 dashDivs"
            >
              <h3 className="QuraniOrangeColor2">الأحزاب المحفوظة</h3>
              <h1 className="quraniBlueColor" id="ahzebNumber">
                {" "}
                {user.ahzeb} حزب
              </h1>
              <div>
                <ProgressBar progress={progress} />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  }
};

export default React.memo(DashboardWithHefdh);
