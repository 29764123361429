import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";
import "./Hero.css";

const Hero = () => {
  return (
    <div>
      <Container fluid>
        <Row className="heromobile">
          <h3 className="herotitlemob"> تعاهدوا </h3>

          <p className="herotextmob">
            منصة تفاعلية متكاملة
            <br></br>
            هدفها مرافقة الطالب
            <br></br>
            في رحلة حفظ القرآن الكريم
          </p>
          <Link
            to="signin" // ID of the target section you want to scroll to
            smooth={true} // Enable smooth scrolling
            duration={1000} // Duration of the scrolling animation in milliseconds
            offset={250}
          >
            <button className="herobtnmob">تسجيل الدخول</button>
          </Link>
        </Row>

        <Row className="herolarge">
          <img
            src={process.env.PUBLIC_URL + "/images/Orange.png"}
            id="logoHero"
            alt="logo"
          />

          <p className="herotext">
            منصة تفاعلية متكاملة
            <br></br>
            هدفها مرافقة الطالب في رحلة
            <br></br>
            حفظ القرآن الكريم
          </p>
          <Link
            to="signin" // ID of the target section you want to scroll to
            smooth={true} // Enable smooth scrolling
            duration={1000} // Duration of the scrolling animation in milliseconds
            offset={-100}
          >
            <button className="herobtn">تسجيل الدخول</button>
          </Link>
        </Row>
      </Container>
    </div>
  );
};
export default Hero;
