import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";
import "./Majmouaat.css";
function Majmouaat() {
  const userAuth = useAuthUser();
  const memoizedTeacherName = useMemo(
    () => `${userAuth().firstName} ${userAuth().lastName}`,
    [userAuth]
  );
  const [groups, setGroups] = useState([]);
  const [students, setStudents] = useState([]);
  const [isUnderConstraction, setisUnderConstraction] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [groupName, setgroupName] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [status, setStatus] = useState("loading");
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);

  useEffect(() => {
    // Fetch groups from your backend API
    // Update the 'groups'  state array
  }, []);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get("/api/user/findMany", {
          params: {
            teacherName: memoizedTeacherName,
          },
        });
        setStudents(response.data);
        setStatus("loaded");
      } catch (error) {
        console.error("Error fetching students:", error);
      }
    };

    fetchStudents();
  }, [memoizedTeacherName]);

  const handleCreateGroup = async () => {
    const id = userAuth()._id;
    try {
      if (!groupName) {
        toast.error("الرجاء إدخال اسم المجموعة", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      if (selectedStudents.length === 0) {
        toast.error("يجب اختيار طالب واحد على الأقل", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      const teacher = await axios.get(`/api/teacher/findOne/${id}`);
      const majmouaat = teacher.data.majmouaat || [];
      if (majmouaat.includes(groupName)) {
        toast.error("اسم المجموعة موجود بالفعل", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      const response = await axios.post("/api/group/createGroup", {
        name: groupName,
        teacherId: id,
        students: selectedStudents,
      });

      toast.success("تم إنشاء المجموعة بنجاح", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      majmouaat.push(groupName);
      await axios.post(`/api/teacher/updateTeacher/${id}`, {
        majmouaat: majmouaat,
      });

      for (const studentId of selectedStudents) {
        const student = await axios.get(`/api/user/findOne/${studentId}`);
        const studentMajmouaa = student.data.majmouaa || [];
        if (!studentMajmouaa.includes(groupName)) {
          studentMajmouaa.push(groupName);
          await axios.post(`/api/user/updateUser/${studentId}`, {
            majmouaa: studentMajmouaa,
          });
        }
      }

      setGroups(majmouaat);
      setSelectedStudents([]);
      setSelectedStudentIds([]);
    } catch (error) {
      toast.error("لا يمكن إنشاء المجموعة ", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.error("Error creating group:", error);
    }
  };

  const handleAssignStudentToGroup = (studentId) => {
    if (selectedStudentIds.includes(studentId)) {
      const updatedSelectedStudents = selectedStudents.filter(
        (id) => id !== studentId
      );
      setSelectedStudents(updatedSelectedStudents);
      setSelectedStudentIds(
        selectedStudentIds.filter((id) => id !== studentId)
      );
    } else {
      const updatedSelectedStudents = [...selectedStudents, studentId];
      setSelectedStudents(updatedSelectedStudents);
      setSelectedStudentIds([...selectedStudentIds, studentId]);
    }
  };

  const filteredStudents = useMemo(
    () =>
      students.filter((student) => {
        const fullName = `${student.firstName} ${student.lastName}`;
        return fullName.toLowerCase().includes(searchInput.toLowerCase());
      }),
    [students, searchInput]
  );

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  if (students.length === 0) {
    return (
      <div>
        <h3 className="loading">لا يمكن إنشاء مجموعات لعدم إنضمام طلابك</h3>
      </div>
    );
  } else {
    return (
      <div className="arabicText">
        <h2 className="arabicText">إنشاء قسم / مجموعة جديدة</h2>
        <input
          type="text"
          placeholder="إسم المجموعة"
          onChange={(e) => setgroupName(e.target.value)}
          className="inputWerd"
          id="groupName"
        />

        <div className="searchBox">
          <input
            className="searchInput"
            id="notArabicText"
            type="text"
            name=""
            placeholder="ابحث عن تلميذ"
            onChange={handleSearchInputChange}
          />
          <button className="searchButton" href="#">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
            >
              <g clipPath="url(#clip0_2_17)">
                <g filter="url(#filter0_d_2_17)">
                  <path
                    d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    shapeRendering="crispEdges"
                  ></path>
                </g>
              </g>
              <defs>
                <filter
                  id="filter0_d_2_17"
                  x="-0.418549"
                  y="3.70435"
                  width="29.7139"
                  height="29.7139"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  ></feColorMatrix>
                  <feOffset dy="4"></feOffset>
                  <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                  <feComposite in2="hardAlpha" operator="out"></feComposite>
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  ></feColorMatrix>
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_2_17"
                  ></feBlend>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_2_17"
                    result="shape"
                  ></feBlend>
                </filter>
                <clipPath id="clip0_2_17">
                  <rect
                    width="28.0702"
                    height="28.0702"
                    fill="white"
                    transform="translate(0.403503 0.526367)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
        <div className="container">
          <div className="row studentsToAdd">
            {filteredStudents.map((student) => (
              <div className="col-lg-4 col-md-6 col-sm-12" key={student._id}>
                <div className="card mb-3 singleProfile" id="majmouatCard">
                  <img
                    src={
                      student.profileImage ||
                      process.env.PUBLIC_URL + "/images/user.png"
                    }
                    alt=""
                    className="card-img-top profileImage"
                  />
                  <div className="card-body2">
                    <h3 className="ahzab">{student.ahzeb} حزب </h3>
                    <h3 className="card-title" id="cardName">
                      {student.firstName} {student.lastName}
                    </h3>
                    <div
                      className="StudentDetailsBtn"
                      id="addToGroup"
                      onClick={() => handleAssignStudentToGroup(student._id)}
                    >
                      {selectedStudentIds.includes(student._id)
                        ? "إخراجه(ا) "
                        : "إختياره(ا)"}
                    </div>

                    {student.majmouaa.length > 0 ? (
                      <div>
                        <p>
                          المجموعة التي ينتمي إليها:
                          <span className="majmouaat">
                            {student.majmouaa.join(", ")}
                          </span>
                        </p>
                      </div>
                    ) : (
                      <p>لا ينتمي إلى أي مجموعة</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className="StudentDetailsBtn"
          id="addMajmouaa"
          onClick={handleCreateGroup}
        >
          إنشاء المجموعة
        </button>

        {/* <h2>Assign Students</h2>
      <select onChange={(e) => setSelectedGroup(e.target.value)}>
        <option value="">Select a Group</option>
        {groups.map((group) => (
          <option key={group._id} value={group._id}>
            {group.name}
          </option>
        ))}
      </select>

      <div className="student-select">
        <Select
          options={students.map((student) => ({
            value: student._id,
            label: student.name,
          }))}
          value={
            selectedStudent
              ? { value: selectedStudent._id, label: selectedStudent.name }
              : null
          }
          onChange={(selectedOption) => {
            const studentId = selectedOption ? selectedOption.value : null;
            setSelectedStudent(studentId);
          }}
          isClearable
        />
        <button onClick={handleAssignStudentToGroup}>Add to Group</button>
      </div>

      <h2>Selected Students:</h2>
      <ul>
        {selectedStudents.map((student) => (
          <li key={student._id}>{student.name}</li>
        ))}
      </ul>

      <button onClick={handleAssignStudents}>Assign</button> */}
      </div>
    );
  }
}

export default Majmouaat;
