import React from "react";
import "./Timeline.css";
const Timeline = () => {
  const specialword = {
    color: "#f05d0e",
    fontWeight: "700",
  };
  return (
    <div>
      <h2 data-aos="fade-up" className="advicetitle">
        الخطوات التي يجب اتباعها لحسن فهم و إستعمال منصة{" "}
        <span style={specialword}>تعاهدوا </span>
      </h2>
      <h5 data-aos="fade-up" className="explicationTimeline">
        يمكن إتمام جميع الخطوات في القسم التالي ( إنضم إلينا )
      </h5>
      <ul className="timeline">
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="time-wrapper">
                <span className="time">أولاً - 1</span>
              </span>
              <span className="flag"> طلب زيادة جمعية قرآنية</span>
            </div>
            <div className="desc">
              يقوم أحد المسؤولين في الجمعية القرآنية بإرسال طلب زيادة جمعيته في
              منصة تعاهدوا بتوفير المعلومات اللازمة و بذلك يستطيع المعلمين إيجاد
              جمعيتهم في مرحلة التسجيل في المنصة
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="time-wrapper">
                <span className="time">ثانياً - 2 </span>
              </span>
              <span className="flag">إنضمام مدرسي الجمعية </span>
            </div>
            <div className="desc">
              يقوم المدرسون بإنشاء حسابات في المرة الأولى للتمتع بخدمات المنصة
              كالمتابعة المستمرة لنشاط الطلاب و مدى التزامهم ببرنامج الحفظ و
              المراجعة. عندما يقوم المعلمون بالتسجيل، سيتمكن طلابهم أيضًا من
              التسجيل واختيار معلمهم عند إنشاء حسابات
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="time-wrapper">
                <span className="time">ثالثاً - 3 </span>
              </span>
              <span className="flag">إنضمام طلاب الجمعية</span>
            </div>
            <div className="desc">
              الآن و بعد إنضمام كل من الجمعية القرآنية و المعلمين إلى المنصة،
              يستطيع الطلاب إنشاء حسابات و التمتع بخدمات المنصة من دروس و اتباع
              برنامج حفظ منظم مع تدوين يومي للتقدم في الحفظ و المراجعة
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Timeline;
