import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "react-auth-kit";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./app/store.js";
import withLoading from "./Components/Student/withLoading";

const AppWithLoading = withLoading(App);


const rootElement = document.getElementById("root");

createRoot(rootElement).render(
  <React.StrictMode>
    <AuthProvider authType={"localstorage"} authName={"_auth"}>
      <BrowserRouter basename="/">
        <Provider store={store}>
          <AppWithLoading />
        </Provider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
