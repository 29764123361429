import { useState, useEffect } from "react";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";

const UserProfile = () => {
  const userAuth = useAuthUser();
  const userId = userAuth()._id;

  const [profilePicture, setProfilePicture] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  useEffect(() => {
    // Fetch the user's data, including the profile picture URL, when the component mounts
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`/api/user/findOne/${userId}`);
        const userData = response.data; // Assuming this includes the imageUrl
        setProfilePicture(userData.profileImage);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handlePictureUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "h6ecoenf");

    try {
      setIsUploading(true);
      setUploadError(null);
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/wassimelfen/image/upload",
        formData
      );

      // Save the uploaded image URL in your database
      const imageUrl = response.data.secure_url;
      await axios.put(`/api/user/update-profile-image/${userId}`, {
        imageUrl,
      });

      // Update the profile picture in the component's state
      setProfilePicture(imageUrl);
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      setUploadError("There was an error uploading the picture.");
      setIsUploading(false);
    }
  };

  return (
    <div className="user-profile">
      <div className="avatar">
        {isUploading && <div className="loader">Uploading...</div>}
        <img
          src={profilePicture || process.env.PUBLIC_URL + "/images/user.png"}
          alt=""
        />
        <p className="LoggedInUserEmail">
          {userAuth().firstName + " " + userAuth().lastName}
        </p>
        {uploadError && <p className="error">{uploadError}</p>}
        {/* <input
          className="changeProfileImageBtn"
          type="file"
          onChange={handlePictureUpload}
          accept="image/*"
        /> */}
        <label className="changeProfileImageBtn" htmlFor="profilePictureInput">
          غير صورة الحساب
        </label>
        <input
          className="changeProfileImageBtn"
          id="profilePictureInput"
          type="file"
          onChange={handlePictureUpload}
          accept="image/*"
          style={{ display: "none" }} // Hide the input element
        />
      </div>
    </div>
  );
};

export default UserProfile;
