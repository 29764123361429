import React, { useState } from "react";
import "./Settings.css";

import UpdatePersonalInfo from "./UpdatePersonalInfo";
import UpdateHefdh from "./UpdateHefdh";
import QuraniFeedBack from "./QuraniFeedBack";

const Settings = () => {
  const [viewToDisplay, setviewToDisplay] = useState("feedback");
  const [selectedDiv, setSelectedDiv] = useState("feedback");

  const handleClick = (view) => {
    setviewToDisplay(view);
    setSelectedDiv(view);
  };

  const getDivStyle = (view) => {
    if (view === selectedDiv) {
      return {
        boxShadow: "0 0 50px -14px rgba(255, 114, 59, 0.8)",
        backgroundColor: "rgba(228, 86, 20, 0.568)",
        border: "solid 2px #e74219b9",
      };
    } else {
      return {};
    }
  };

  return (
    <div className="container SettingsContainer">
      <div className="row settingsRow d-flex ">
        <div
          className="col settingsDivs"
          onClick={() => handleClick("feedback")}
          style={getDivStyle("feedback")}
        >
          المساهمة في تطوير تعاهدوا
        </div>
        <div
          className="col settingsDivs"
          onClick={() => handleClick("hefdh")}
          style={getDivStyle("hefdh")}
        >
          تغيير بيانات الحفظ
        </div>
        <div
          className="col settingsDivs"
          onClick={() => handleClick("personal")}
          style={getDivStyle("personal")}
        >
          تغيير المعطيات الشخصية
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          {viewToDisplay === "hefdh" && <UpdateHefdh />}
          {viewToDisplay === "feedback" && <QuraniFeedBack />}
          {viewToDisplay === "personal" && <UpdatePersonalInfo />}
        </div>
      </div>
    </div>
  );
};

export default Settings;
