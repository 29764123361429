import "./App.css";
import Home from "./Components/Home";
import { Routes, Route } from "react-router-dom";
import TeacherAuth from "./Components/Authentication/TeacherAuth";
import HomeStudent from "./Components/Student/HomeStudent";
import Werd from "./Components/Student/Werd/Werd";
import { RequireAuth } from "react-auth-kit";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddJamiia from "./Components/Authentication/AddJamiia";
import HomeTeacher from "./Components/Teacher/HomeTeacher";
import NewStudentAuth from "./Components/Authentication/NewStudentAuth";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/studentauth" element={<NewStudentAuth />} />
        <Route path="/teacherauth" element={<TeacherAuth />} />
        <Route path="/addjamiia" element={<AddJamiia />} />
        <Route
          path="/homestudent"
          element={
            <RequireAuth loginPath="/studentauth">
              <HomeStudent />
            </RequireAuth>
          }
        />
        <Route
          path="/hometeacher"
          element={
            <RequireAuth loginPath="/teacherauth">
              <HomeTeacher />
            </RequireAuth>
          }
        />
        <Route path="/werd" element={<Werd />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        draggable
        theme="dark"
      />
    </div>
  );
}

export default App;
