import { createSlice } from "@reduxjs/toolkit";

const hefdhDirectionSlice = createSlice({
  name: "hefdhDirection",
  initialState: "",
  reducers: {
    setHefdhDirection: (state, action) => action.payload,
  },
});

export const { setHefdhDirection } = hefdhDirectionSlice.actions;

export default hefdhDirectionSlice.reducer;
