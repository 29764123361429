import React, { useState } from "react";
import "./NavBar2.css";

function Navbar() {
  const [activeSection, setActiveSection] = useState("");

  const handleScrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.offsetTop; // Get the top position of the section
      window.scrollTo({ top: offsetTop, behavior: "smooth" }); // Scroll to the top of the section
      setActiveSection(sectionId);
    }
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container navPhoneView">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeSection === "contactUs" ? "active" : ""
                }`}
                href="contact-us"
                onClick={(e) => handleScrollToSection(e, "contactUs")}
              >
                تواصل معنا
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeSection === "barnamaj" ? "active" : ""
                }`}
                href="about-us"
                onClick={(e) => handleScrollToSection(e, "barnamaj")}
              >
                برنامج الحفظ
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeSection === "momayizat" ? "active" : ""
                }`}
                href="about-us"
                onClick={(e) => handleScrollToSection(e, "momayizat")}
              >
                المميزات
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeSection === "signin" ? "active" : ""
                }`}
                href="signin"
                onClick={(e) => handleScrollToSection(e, "signin")}
              >
                انضم إلينا
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeSection === "timeline" ? "active" : ""
                }`}
                href="about-us"
                onClick={(e) => handleScrollToSection(e, "timeline")}
              >
                عن المنصة
              </a>
            </li>

            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeSection === "hero" ? "active" : ""
                }`}
                href="hero"
                onClick={(e) => handleScrollToSection(e, "hero")}
              >
                الرئيسية
              </a>
            </li>
          </ul>
        </div>

        <a
          className="navbar-brand"
          href="hero"
          onClick={(e) => handleScrollToSection(e, "hero")}
        >
          <img
            src={process.env.PUBLIC_URL + "/images/bleuNavbar.png"}
            className="navLogo"
            // id="logoHero"
            width="100"
            alt="logo"
          />
        </a>
      </div>
    </nav>
  );
}

export default Navbar;
