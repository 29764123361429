import { createSlice } from "@reduxjs/toolkit";

const currHezbSlice = createSlice({
  name: "currHezb",
  initialState: "",
  reducers: {
    setcurrHezb: (state, action) => action.payload,
  },
});

export const { setcurrHezb } = currHezbSlice.actions;

export default currHezbSlice.reducer;
