import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthUser } from "react-auth-kit";

const UpdateTeacherInfo = () => {
  const userAuth = useAuthUser();
  const id = userAuth()._id;

  const [teacherData, setTeacherData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    fetchTeacherData();
  }, []);

  const fetchTeacherData = async () => {
    try {
      const id = userAuth()._id;
      const response = await axios.get(`/api/teacher/findOne/${id}`);
      const data = response.data;
      setTeacherData(data);
      if (data.phoneNumber) {
        setPhoneNumber(data.phoneNumber);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const { firstName, lastName, email } = teacherData;

  useEffect(() => {
    if (teacherData.phoneNumber) {
      setPhoneNumber(teacherData.phoneNumber);
    }
  }, [teacherData.phoneNumber]);

  const MAX_RETRIES = 3;
  let retryCount = 0;

  const isValid = () => {
    // Add your validation logic here
    if (firstName.trim() === "") {
      toast.error("الرجاء إدخال الاسم الأول", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (lastName.trim() === "") {
      toast.error("الرجاء إدخال اسم العائلة", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      toast.error("الرجاء إدخال بريد إلكتروني صحيح", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const handleError = (error) => {
    console.log(error);
    if (retryCount < MAX_RETRIES) {
      retryCount++;
      setTimeout(() => {
        handleSubmit();
      }, 1000);
    } else {
      toast.error("لا يمكن تحديث المعلومات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!isValid()) return;
    try {
      const result = await axios.post(`/api/teacher/updateTeacher/${id}`, {
        firstName,
        lastName,
        email,
        phoneNumber,
      });
      toast.success("تم تحديث المعلومات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="qurani-feedback-container">
      <form
        onSubmit={handleSubmit}
        className="qurani-feedback-form"
        name="updateInfo"
      >
        <div className="qurani-feedback-inputs-container">
          <div className="qurani-feedback-input-container">
            <label>الاسم</label>
            <input
              id="email"
              type="text"
              value={teacherData.firstName || ""}
              onChange={(e) =>
                setTeacherData({ ...teacherData, firstName: e.target.value })
              }
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label>اللقب</label>
            <input
              id="email"
              type="text"
              value={teacherData.lastName || ""}
              onChange={(e) =>
                setTeacherData({ ...teacherData, lastName: e.target.value })
              }
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label>البريد الالكتروني</label>
            <input
              id="email"
              type="email"
              value={teacherData.email || ""}
              onChange={(e) =>
                setTeacherData({ ...teacherData, email: e.target.value })
              }
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label>الهاتف الجوال</label>
            <input
              id="email"
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <button type="submit" className="qurani-feedback-submit-button">
            تحديث
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateTeacherInfo;
