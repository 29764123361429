import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./QuraniFeedBack.css";
import { toast } from "react-toastify";
import StarRating from "./StarRating";

const QuraniFeedback = () => {
  const [rating, setRating] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!rating || !email || !feedback) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    const templateParams = {
      rating,
      email,
      feedback,
    };

    emailjs
      .send(
        "service_9m3alyf",
        "template_j4jxvrd",
        templateParams,
        "3rr5FLm6ojgfhsPX3"
      )
      .then(
        (result) => {
          toast.success("بارك الله فيكم", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setSuccessMessage("شكراً على رسالتكم");
          setRating("");
          setEmail("");
          setFeedback("");
        },
        (error) => {
          toast.error("لا يمكن إرسال المعلومات", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setErrorMessage("لا يمكن إرسال المعلومات");
        }
      );
  };

  return (
    <div className="qurani-feedback-container">
      <h2 className="qurani-feedback-header">نرحب بملاحظاتكم و اقتراحاتكم</h2>
      <form
        onSubmit={handleSubmit}
        className="qurani-feedback-form"
        name="taahaduFeedback"
      >
        <div className="qurani-feedback-inputs-container">
          <div className="qurani-feedback-input-container">
            <label htmlFor="rating">التقييم</label>
            <StarRating />
          </div>
          <div className="qurani-feedback-input-container">
            <label htmlFor="email"> البريد الالكتروني</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="البريد الالكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label htmlFor="feedback">الملاحظات و الإقتراحات</label>
            <textarea
              id="feedback"
              name="feedback"
              placeholder="الرجاء الكتابة هنا..."
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
            ></textarea>
          </div>
        </div>
        {errorMessage && (
          <div className="qurani-feedback-error">{errorMessage}</div>
        )}
        {successMessage && (
          <div className="qurani-feedback-success">{successMessage}</div>
        )}
        <button type="submit" className="qurani-feedback-submit-button">
          إرسال
        </button>
      </form>
    </div>
  );
};

export default QuraniFeedback;
