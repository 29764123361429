import React from "react";

const Ikhtibar = () => {
  return (
    <div>
      <h3 className="loading">لم يختبرك معلمك بعد</h3>
    </div>
  );
};

export default Ikhtibar;
