import React, { useState } from "react";
import NextPrevMahfoudha from "../../Student/Hefdh/NextPrevMahfoudha";
import NextPrevNotMahfoudha from "../../Student/Hefdh/NextPrevNotMahfoudha";
import { Table } from "react-bootstrap";
import { FaBackward } from "react-icons/fa";

const CheckHefdh = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTwo, setcurrentPageTwo] = useState(1);
  const wordsPerPage = 6;

  function hefdhDeadline(startDate, pagesPerWeek) {
    // Split the start date into day, month, and year components
    const [day, month, year] = startDate.split("/");

    // Create a new Date object with the components in the correct order (month - 1 because months are zero-based)
    const startDateObj = new Date(`${month}/${day}/${year}`);

    // Calculate the number of weeks required to memorize 10 pages
    const numWeeks = Math.ceil(10 / pagesPerWeek);

    // Calculate the finish date by adding the number of weeks to the start date
    const finishDate = new Date(
      startDateObj.getTime() + numWeeks * 7 * 24 * 60 * 60 * 1000
    );

    // Format the finish date as a string with the desired format 'dd/mm/yyyy'
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const finishDateString = finishDate.toLocaleDateString("en-GB", options);

    return finishDateString;
  }

  const changeToStudentsView = () => {
    props.changeToStudentsView("students");
  };

  if (props.studentData.isHefdhActive === false) {
    return (
      <div>
        <div>
          <div>
            <button
              className="backword"
              id="marginBTMBackBtn"
              onClick={changeToStudentsView}
            >
              <FaBackward />
              الرجوع إلى قائمة التلاميذ
            </button>
          </div>
        </div>
        <h3 className="loading">
          مازال لم يفعل خاصية الحفظ بعد{" "}
          {props.studentData.firstName + " " + props.studentData.lastName}
        </h3>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <div>
            <button
              className="backword"
              id="marginBTMBackBtn"
              onClick={changeToStudentsView}
            >
              <FaBackward />
              الرجوع إلى قائمة التلاميذ
            </button>
          </div>
          <h3 className="werdStudent" id="hefdhStudent">
            هذه نتائج الحفظ الخاصة بالطالب
            {props.studentData.firstName +
              " " +
              props.studentData.lastName}{" "}
          </h3>
        </div>
        <div className="hefdhHistory container">
          <div className="row hefdhContainer">
            <div className="col-xl-4 col-md-6 col-xxl-3 hefdhDivs">
              <div className="blueDiv">
                <h6>{props.studentData.ahzeb} أحزاب</h6>
              </div>
              <h6 className="hFour">الحزب الذي أنا بصدد حفظه:</h6>
              <h6 className="hefdhValues">
                الحزب{" "}
                {
                  props.studentData.souarMahfoudhaWithUs[
                    props.studentData.souarMahfoudhaWithUs.length - 1
                  ].hezb
                }
              </h6>
              <h6 className="hFour"> تاريخ البدء في حفظ الحزب الحالي:</h6>
              <h6 className="hefdhValues">
                {
                  props.studentData.souarMahfoudhaWithUs[
                    props.studentData.souarMahfoudhaWithUs.length - 1
                  ].dateStartingHefdh
                }
              </h6>
              <h6 className="hFour">
                عدد الصفحات المطالب بحفظها في الاسبوع:{" "}
                <span className="hefdhValues">
                  {props.studentData.hefdhPerWeek}
                </span>
              </h6>
              <br />
              <h6 className="hFour">يجب إنهاء حفظ الحزب الحالي قبل تاريخ :</h6>
              <h6 className="hefdhValues">
                {hefdhDeadline(
                  props.studentData.souarMahfoudhaWithUs[
                    props.studentData.souarMahfoudhaWithUs.length - 1
                  ].dateStartingHefdh,
                  props.studentData.hefdhPerWeek
                )}
              </h6>
              <br />
            </div>
            <div className="col-xl-4 col-md-6 col-xxl-3 souarDivs">
              <h5 className="hFour">السور التي احفظها و الحمد لله</h5>
              <div className="greenDiv">
                <h6>{props.studentData.souarMahfoudha.length} سورة</h6>
              </div>
              <ul className="hFour">
                {props.studentData.souarMahfoudha
                  .slice(
                    (currentPage - 1) * wordsPerPage,
                    currentPage * wordsPerPage
                  )
                  .map((word, index) => (
                    <li key={index}>{word}</li>
                  ))}
              </ul>
              <NextPrevMahfoudha
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                souarMahfoudh={props.studentData.souarMahfoudha}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-xxl-3 souarDivs">
              <h5 className="hFour">السور التي لم احفظها بعد</h5>
              <div className="orangeDiv">
                <h6>{props.studentData.souarNotMahfoudha.length} سورة</h6>
              </div>
              <ul className="hFour">
                {props.studentData.souarNotMahfoudha
                  .slice(
                    (currentPageTwo - 1) * wordsPerPage,
                    currentPageTwo * wordsPerPage
                  )
                  .map((word, index) => (
                    <li key={index}>{word}</li>
                  ))}
              </ul>
              <NextPrevNotMahfoudha
                currentPage={currentPageTwo}
                setCurrentPage={setcurrentPageTwo}
                souarNotMahfoudha={props.studentData.souarNotMahfoudha}
              />
            </div>

            <div className="col-xl-4 col-md-6 col-xxl-3 hefdhWithUsDivs">
              <div className="pinkDiv">
                <h6>مع تعاهدوا</h6>
              </div>
              <p className="withQuraniText">الأحزاب المحفوظة مع تعاهدوا</p>
              <Table responsive style={{ fontWeight: "600" }}>
                <thead>
                  <tr>
                    <th>هل حفظته في الوقت</th>
                    {/* <th>الموعد النهائي للحفظ</th> */}
                    <th>أقصى أجل للحفظ</th>
                    <th>تاريخ نهاية الحفظ</th>
                    <th>تاريخ بدء الحفظ</th>
                    <th>رقم الحزب</th>
                  </tr>
                </thead>
                <tbody>
                  {props.studentData.souarMahfoudhaWithUs.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        backgroundColor:
                          item.isOnTime === "نعم"
                            ? "#36bd3d"
                            : item.isOnTime === "لا"
                            ? "#bb5b5b"
                            : item.isOnTime === "-"
                            ? "#b8bddd"
                            : "",
                      }}
                    >
                      <td>{item.isOnTime}</td>
                      <td>
                        {hefdhDeadline(
                          item.dateStartingHefdh,
                          props.studentData.hefdhPerWeek
                        )}
                      </td>
                      <td>{item.finishHefdhDate}</td>
                      <td>{item.dateStartingHefdh}</td>
                      <td>{item.hezb}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CheckHefdh;
