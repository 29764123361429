import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice.js";
import hefdhDirectionReducer from "./features/hefdhDirectionSlice.js";
import currHezbReducer from "./features/currHezbSlice.js";

const store = configureStore({
  reducer: {
    user: userReducer,
    hefdhDirection: hefdhDirectionReducer,
    currHezb: currHezbReducer,
  },
});

export default store;
