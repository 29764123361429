import { createAsyncThunk, createSlice, createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUserById = createAsyncThunk(
  "user/fetchUserById",
  async (id) => {
    const response = await axios.get(`api/user/findOne/${id}`);
    return response.data;
  }
);

export const fetchTeacherById = createAsyncThunk(
  "user/fetchUserById",
  async (id) => {
    const response = await axios.get(`api/teacher/findOne/${id}`);
    return response.data;
  }
);

export const clearUser = createAction("user/clearUser");

const userSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(clearUser, (state, action) => {
      return {};
    });
  },
});

export default userSlice.reducer;
