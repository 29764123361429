import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Presentation.css";

const Presentation2 = () => {
  const specialword = {
    color: "#f05d0e",
    fontWeight: "900",
  };

  return (
    <div>
      <Container className="presContainer">
        <h1 className="advicetitle">
          مميزات منصة<span style={specialword}> تعاهدوا </span>
        </h1>
        <Row>
          <Col sm={6} className="presCard">
            <Col sm={7} className="presentationhalf2">
              <h3 className="presentationtitle"> دروس تجويد</h3>
              <p className="presentationtext">
                توفر المنصة لكل طالب دروس متنوعة و مراجع علمية في علم التجويد و
                في أصول رواية قالون للتعلم و لتحسين مستوى التجويد لديهم
              </p>
              {/* <p className="presentationtext">
                يطلع المعلم على تقدم الطالب في الحفظ و احترامه لتاريخ إتمام كل
                حزب{" "}
              </p> */}
            </Col>
            <Col sm={5} className="presentationhalf1">
              <img
                src={process.env.PUBLIC_URL + "/images/tajweed.PNG"}
                className="presentationpic"
                alt=""
              />
            </Col>
          </Col>

          <Col sm={6} className="presCard">
            <Col sm={7} className="presentationhalf2">
              <h3 className="presentationtitle"> الحفظ الجديد </h3>
              <p className="presentationtext">
                تحث المنصة الطالب على الحفظ بتحديد المقدار الذي سيحفظه كل أسبوع
                ثم تراقب إلتزامه بحساب التاريخ الذي لا يجب تجاوزه لإنهاء حفظ
                الحزب الحالي. يطلع المدرس على تقدم حفظ جميع طلابه
              </p>
            </Col>
            <Col sm={5} className="presentationhalf1">
              <img
                src={process.env.PUBLIC_URL + "/images/hefth.png"}
                className="presentationpic"
                alt=""
              />
            </Col>
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="presCard">
            <Col sm={7} className="presentationhalf2">
              <h3 className="presentationtitle">التقييم المتواصل</h3>
              <p className="presentationtext">
                يستطيع كل طالب أن يعرف تاريخ إتمام حفظه لكتاب الله إن شاء الله و
                يصبح لديه قاعدة بيانات مفصلة تتعلق بحفظ كل حزب: تاريخ بداية حفظ
                الحزب، تاريخ إنهاء حفظه، مدى إلتزامه بمقدار الحفظ المقرر
                أسبوعياً في ذلك الحزب، ...
              </p>
            </Col>

            <Col sm={5} className="presentationhalf1">
              <img
                src={process.env.PUBLIC_URL + "/images/ta9dir.PNG"}
                className="presentationpic"
                alt=""
              />
            </Col>
          </Col>

          <Col sm={6} className="presCard">
            <Col sm={7} className="presentationhalf2">
              <h3 className="presentationtitle"> ورد المراجعة اليومي</h3>
              <p className="presentationtext">
                أحد أبرز أهداف المنصة هو جعل الطالب و المعلم يداومون على قرائة
                وردهم اليومي لتثبيت محفوظهم و ذلك بتدوين النصيب المقروء في كل
                يوم. يستطيع المدرس أن يراقب الورد اليومي لجميع طلابه
              </p>
            </Col>
            <Col sm={5} className="presentationhalf1">
              <img
                src={process.env.PUBLIC_URL + "/images/werd.png"}
                className="presentationpic"
                alt=""
              />
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Presentation2;
