import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";
import FormHefdhSteps from "./FormHefdhSteps";
import "./Hefdh.css";
import { fetchUserById } from "../../../app/features/userSlice";
import NextPrevMahfoudha from "./NextPrevMahfoudha";
import NextPrevNotMahfoudha from "./NextPrevNotMahfoudha";
import Spinner from "../Spinner";
import { Table } from "react-bootstrap";

const Hefdh = () => {
  const [isHefdhActive, setisHefdhActive] = useState(false);
  const userAuth = useAuthUser();
  const [status, setstatus] = useState("loading");
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTwo, setcurrentPageTwo] = useState(1);
  const wordsPerPage = 7;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user || !user._id) {
      // User data not yet available in the store, so fetch it from the API
      const id = userAuth()._id;
      dispatch(fetchUserById(id));
      let hefdhStatus = user.isHefdhActive;
      setisHefdhActive(hefdhStatus);
    } else {
      // User data is already in the store, so update the component state with it
      setstatus("loaded");
      let hefdhStatus = user.isHefdhActive;
      setisHefdhActive(hefdhStatus);
    }
  }, [user, dispatch, isHefdhActive, userAuth]);

  useEffect(() => {
    let isCancelled = false;
    if (status === "loading") {
      const id = userAuth()._id;
      axios
        .get(`api/user/findOne/${id}`)
        .then(({ data }) => {
          if (!isCancelled) {
            setstatus("loaded");
          }
        })
        .catch((error) => {
          if (!isCancelled) {
            setError(error);
            console.log(error);
            setstatus("loading");
          }
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [status, userAuth]);

  function hefdhDeadline(startDate, pagesPerWeek) {
    // Split the start date into day, month, and year components
    const [day, month, year] = startDate.split("/");

    // Create a new Date object with the components in the correct order (month - 1 because months are zero-based)
    const startDateObj = new Date(`${month}/${day}/${year}`);

    // Calculate the number of weeks required to memorize 10 pages
    const numWeeks = Math.ceil(10 / pagesPerWeek);

    // Calculate the finish date by adding the number of weeks to the start date
    const finishDate = new Date(
      startDateObj.getTime() + numWeeks * 7 * 24 * 60 * 60 * 1000
    );

    // Format the finish date as a string with the desired format 'dd/mm/yyyy'
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const finishDateString = finishDate.toLocaleDateString("en-GB", options);

    return finishDateString;
  }
  //*? example :
  //  hefdhDeadline("05/11/2023",5) => output: ("5/25/2023");
  // input date format should be : MM/DD/YYYY

  if (status === "loading") {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  } else if (isHefdhActive === false) {
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-9 mx-auto">
              <div className="card flex-row my-5 border-0 shadow rounded-3 overflow-hidden">
                <div className="card-img-left d-none d-md-flex"></div>
                <div className="card-body p-4 p-sm-5">
                  <FormHefdhSteps />
                  <hr className="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="hefdhHistory container">
        <div className="row hefdhContainer">
          <div className="col-xl-4 col-md-6 col-xxl-3 hefdhDivs">
            <div className="blueDiv">
              <h6>{user.ahzeb} أحزاب</h6>
            </div>
            <h6 className="hFour">الحزب الذي أنا بصدد حفظه:</h6>
            <h6 className="hefdhValues">
              الحزب{" "}
              {
                user.souarMahfoudhaWithUs[user.souarMahfoudhaWithUs.length - 1]
                  .hezb
              }
            </h6>
            <h6 className="hFour"> تاريخ البدء في حفظ الحزب الحالي:</h6>
            <h6 className="hefdhValues">
              {
                user.souarMahfoudhaWithUs[user.souarMahfoudhaWithUs.length - 1]
                  .dateStartingHefdh
              }
            </h6>
            <h6 className="hFour">
              عدد الصفحات المطالب بحفظها في الاسبوع:{" "}
              <span className="hefdhValues">{user.hefdhPerWeek}</span>
            </h6>
            <br />
            <h6 className="hFour">يجب إنهاء حفظ الحزب الحالي قبل تاريخ :</h6>
            <h6 className="hefdhValues">
              {hefdhDeadline(
                user.souarMahfoudhaWithUs[user.souarMahfoudhaWithUs.length - 1]
                  .dateStartingHefdh,
                user.hefdhPerWeek
              )}
            </h6>
            <br />
          </div>
          <div className="col-xl-4 col-md-6 col-xxl-3 souarDivs">
            <h5 className="hFour">السور التي احفظها و الحمد لله</h5>
            <div className="greenDiv">
              <h6>{user.souarMahfoudha.length} سورة</h6>
            </div>
            <ul className="hFour">
              {user.souarMahfoudha
                .slice(
                  (currentPage - 1) * wordsPerPage,
                  currentPage * wordsPerPage
                )
                .map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
            </ul>
            <NextPrevMahfoudha
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              souarMahfoudh={user.souarMahfoudha}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-xxl-3 souarDivs">
            <h5 className="hFour">السور التي لم احفظها بعد</h5>
            <div className="orangeDiv">
              <h6>{user.souarNotMahfoudha.length} سورة</h6>
            </div>
            <ul className="hFour">
              {user.souarNotMahfoudha
                .slice(
                  (currentPageTwo - 1) * wordsPerPage,
                  currentPageTwo * wordsPerPage
                )
                .map((word, index) => (
                  <li key={index}>{word}</li>
                ))}
            </ul>
            <NextPrevNotMahfoudha
              currentPage={currentPageTwo}
              setCurrentPage={setcurrentPageTwo}
              souarNotMahfoudha={user.souarNotMahfoudha}
            />
          </div>
          <div className="col-xl-4 col-md-6 col-xxl-3 hefdhWithUsDivs">
            <div className="pinkDiv">
              <h6>مع تعاهدوا</h6>
            </div>
            <p className="withQuraniText">الأحزاب المحفوظة مع تعاهدوا</p>
            <Table responsive style={{ fontWeight: "600" }}>
              <thead>
                <tr>
                  <th>هل حفظته في الوقت</th>
                  {/* <th>الموعد النهائي للحفظ</th> */}
                  <th>أقصى أجل للحفظ</th>
                  <th>تاريخ نهاية الحفظ</th>
                  <th>تاريخ بدء الحفظ</th>
                  <th>رقم الحزب</th>
                </tr>
              </thead>
              <tbody>
                {user.souarMahfoudhaWithUs.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      backgroundColor:
                        item.isOnTime === "نعم"
                          ? "#36bd3d"
                          : item.isOnTime === "لا"
                          ? "#bb5b5b"
                          : item.isOnTime === "-"
                          ? "#b8bddd"
                          : "",
                    }}
                  >
                    <td>{item.isOnTime}</td>
                    <td>
                      {hefdhDeadline(item.dateStartingHefdh, user.hefdhPerWeek)}
                    </td>
                    <td>{item.finishHefdhDate}</td>
                    <td>{item.dateStartingHefdh}</td>
                    <td>{item.hezb}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
};

export default Hefdh;
