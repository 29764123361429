import React from "react";

const ModalContent = (props) => {
  return (
    <div>
      <h4 className="titleWerdCont">نصيب الورد المقروء</h4>
      <h2 className="werdContent">{props.currentWerd}</h2>
    </div>
  );
};

export default ModalContent;
