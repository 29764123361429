import React, { useState, useEffect } from "react";
import Spinner from "./Spinner";

const withLoading = (Component) => {
  const WrappedComponent = (props) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      // Set loading to false once the component has mounted
      setLoading(false);
    }, []);

    if (loading) {
      return <Spinner />;
    }

    return <Component {...props} />;
  };

  return WrappedComponent;
};

export default withLoading;
