import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useAuthUser } from "react-auth-kit";

const UpdatePersonalInfo = () => {
  const user = useSelector((state) => state.user);
  const userAuth = useAuthUser();
  const id = userAuth()._id;
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (user.phoneNumber) {
      setPhoneNumber(user.phoneNumber);
    }
  }, [user.phoneNumber]);

  const MAX_RETRIES = 3;
  let retryCount = 0;

  const isValid = () => {
    // Add your validation logic here
    if (firstName.trim() === "") {
      toast.error("الرجاء إدخال الاسم الأول", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (lastName.trim() === "") {
      toast.error("الرجاء إدخال اسم العائلة", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      toast.error("الرجاء إدخال بريد إلكتروني صحيح", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return false;
    }
    return true;
  };

  const handleError = (error) => {
    console.log(error);
    if (retryCount < MAX_RETRIES) {
      retryCount++;
      setTimeout(() => {
        handleSubmit();
      }, 1000);
    } else {
      toast.error("لا يمكن تحديث المعلومات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid()) return;
    try {
      const result = await axios.post(`/api/user/updateUser/${id}`, {
        firstName,
        lastName,
        email,
        phoneNumber,
      });
      toast.success("تم تحديث المعلومات", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      window.location.reload();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="qurani-feedback-container">
      <form
        onSubmit={handleSubmit}
        className="qurani-feedback-form"
        name="updateInfo"
      >
        <div className="qurani-feedback-inputs-container">
          <div className="qurani-feedback-input-container">
            <label>الاسم</label>
            <input
              id="email"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label>اللقب</label>
            <input
              id="email"
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label>البريد الالكتروني</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="qurani-feedback-input-container">
            <label>الهاتف الجوال</label>
            <input
              id="email"
              type="tel"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <button type="submit" className="qurani-feedback-submit-button">
            تحديث
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdatePersonalInfo;
