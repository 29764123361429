import React, { useEffect, useState } from "react";
import "./HomeStudent.css";
import Werd from "./Werd/Werd";
import Hefdh from "./Hefdh/Hefdh";
import Ikhtibar from "./Ikhtibar";
import Settings from "./Settings/Settings";
import Course from "./Course/Course";
import GeneralDashboard from "./Dashboard/GeneralDashboard";
import UserProfile from "./UserProfile";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearUser } from "../../app/features/userSlice";

const HomeStudent = () => {
  const user = useSelector((state) => state.user);
  const userAuth = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeSection, setActiveSection] = useState(null);
  const [currentDisplayedComponent, setcurrentDisplayedComponent] =
    useState("dashboard");

  useEffect(() => {
    if (user.isHefdhActive === false) {
      setcurrentDisplayedComponent("hefdh");
    } else if (user.isHefdhActive === true) {
      setcurrentDisplayedComponent("dashboard");
    }
  }, [user]);

  const logOut = () => {
    dispatch(clearUser());
    signOut();
    navigate("/studentauth");
  };

  function changeView() {
    if (currentDisplayedComponent === "dashboard") {
      return <GeneralDashboard />;
    } else if (currentDisplayedComponent === "werd") {
      return <Werd />;
    } else if (currentDisplayedComponent === "hefdh") {
      return <Hefdh />;
    } else if (currentDisplayedComponent === "ikhtibar") {
      return <Ikhtibar />;
    } else if (currentDisplayedComponent === "dourous") {
      return <Course />;
    } else if (currentDisplayedComponent === "settings") {
      return <Settings />;
    }
  }

  return (
    <div className="sideNav">
      <nav className="menu" tabIndex="0">
        <div className="smartphone-menu-trigger"></div>
        {/* <header className="avatar">
          <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="" />
          <p className="LoggedInUserEmail">
            {userAuth().firstName + " " + userAuth().lastName}
          </p>
        </header> */}

        <UserProfile />
        <ul>
          <li
            tabIndex="0"
            id={activeSection === "dashboard" ? "activeSection" : ""}
            className="icon-dashboard"
            onClick={() => {
              setcurrentDisplayedComponent("dashboard");
              setActiveSection("dashboard");
            }}
          >
            <span>الصفحة الرئيسية</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "werd" ? "activeSection" : ""}
            className="icon-werd"
            onClick={() => {
              setcurrentDisplayedComponent("werd");
              setActiveSection("werd");
            }}
          >
            <span>الورد اليومي</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "hefdh" ? "activeSection" : ""}
            className="icon-hefdh"
            onClick={() => {
              setcurrentDisplayedComponent("hefdh");
              setActiveSection("hefdh");
            }}
          >
            <span>حفظ القرأن</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "ikhtibar" ? "activeSection" : ""}
            className="icon-test"
            onClick={() => {
              setcurrentDisplayedComponent("ikhtibar");
              setActiveSection("ikhtibar");
            }}
          >
            <span>إختبارات </span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "dourous" ? "activeSection" : ""}
            className="icon-course"
            onClick={() => {
              setcurrentDisplayedComponent("dourous");
              setActiveSection("dourous");
            }}
          >
            <span>دروس تجويد</span>
          </li>
          <li
            tabIndex="0"
            id={activeSection === "settings" ? "activeSection" : ""}
            className="icon-settings"
            onClick={() => {
              setcurrentDisplayedComponent("settings");
              setActiveSection("settings");
            }}
          >
            <span>الإعدادات</span>
          </li>
          <li tabIndex="0" className="icon-logout" onClick={logOut}>
            <span>خروج</span>
          </li>
        </ul>
      </nav>
      <main>{changeView()}</main>
    </div>
  );
};

export default HomeStudent;
